<script setup>
import { onMounted, useNuxtApp } from '#imports';

import UiButton from '@/components/ui/button/UiButton.vue';
import BackgroundBlock from '@/components/blocks/BackgroundBlock.vue';

const { $tp } = useNuxtApp();

defineProps({
  error: {
    type: Object,
    required: true,
  },
});

//<editor-fold desc="ANALYTIC">
import { commonAnalytic } from '@/analytic/common';

onMounted(() => {
  commonAnalytic.error404();
});
//</editor-fold>
</script>
<template>
  <div class="error">
    <BackgroundBlock theme="fixed" />

    <div class="error__body">
      <div class="error__content">
        <div class="error__heading">
          <h2 class="error__font error__font--heading">404</h2>
        </div>

        <div class="error__description">
          <p class="error__font error__font--description">
            {{ $tp('запрашиваемая вами страница не найдена') }}
          </p>
        </div>

        <UiButton
          to="/"
          theme="white"
          class="error__button"
          text="Перейти на главную"
        />
      </div>
    </div>
  </div>
</template>
<style scoped lang="scss">
.error {
  $parent: &;

  display: flex;
  flex-grow: 1;
  padding: em(80) em(30);

  &__font {
    &--heading {
      font-size: em(120);
      line-height: 1;
      color: $color-white;
      text-transform: uppercase;
      letter-spacing: 0.05em;

      @include media-breakpoint-down(sm) {
        font-family: $font-family-secondary;
        font-size: em(80);
        font-weight: 500;
        line-height: 1.3;
      }
    }

    &--description {
      font-size: em(14);
      line-height: 1.8;
      color: $color-white;
      text-transform: uppercase;
      letter-spacing: 0.2em;
    }
  }

  &__body {
    display: flex;
    margin: auto;
  }

  &__content {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: em(704);
    text-align: center;

    @include media-breakpoint-down(sm) {
      width: 100%;
    }
  }

  &__description {
    max-width: em(435);
    margin-top: em(32);

    @include media-breakpoint-down(sm) {
      margin-top: em(16);
    }
  }

  &__button {
    min-width: em(281);
    margin-top: em(56);

    @include media-breakpoint-down(sm) {
      min-width: em(224);
      margin-top: em(48);
    }
  }
}
</style>
