<script setup>
import CIcon from '@/components/common/CIcon.vue';

const props = defineProps({
  theme: {
    type: String,
    default: 'default',
    validator(theme) {
      return ['default', 'dark', 'white', 'black'].includes(theme);
    },
  },

  size: {
    type: String,
    default: undefined,
    validator(size) {
      return ['s', 'l'].includes(size);
    },
  },

  icon: {
    type: String,
    default: 'close',
    validator(icon) {
      return ['close', 'close-thin'].includes(icon);
    },
  },
});

const emit = defineEmits(['click']);

const emitClick = (e) => {
  emit('click', e);
};
</script>
<template>
  <button
    type="button"
    class="ui-button-close"
    :class="{
      [`ui-button-close--theme--${props.theme}`]: props.theme,
      [`ui-button-close--size--${props.size}`]: props.size,
    }"
    @click="emitClick"
  >
    <CIcon :name="props.icon" class="ui-button-close__icon" />
  </button>
</template>

<style scoped lang="scss">
.ui-button-close {
  $parent: &;

  width: em(40);
  height: em(40);
  background-color: transparent;
  border: none;
  transition: opacity $time-default ease-out;

  @include hover {
    opacity: 0.8;
  }

  &__icon {
    flex-shrink: 0;
    width: 100%;
    height: 100%;
  }

  &--theme {
    &--default {
      color: $color-grey-300;
    }

    &--white {
      color: $color-white;
    }

    &--dark {
      color: $color-black-100;
    }

    &--black {
      color: $color-black-100;
    }
  }

  &--size {
    &--s {
      width: em(40);
      height: em(40);
    }

    &--l {
      width: em(104);
      height: em(104);

      @include media-breakpoint-down(lg) {
        width: em(75);
        height: em(75);
      }

      @include media-breakpoint-down(sm) {
        width: em(52);
        height: em(52);
      }
    }
  }
}
</style>
