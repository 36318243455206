<script setup>
import { onMounted, ref, useNuxtApp, onBeforeUnmount } from '#imports';
import { useCustomBreakpoints } from '@/composables/useCustomBreakpoints';

const { $gsap } = useNuxtApp();
const breakpoints = useCustomBreakpoints();
const isSmBreakpoint = ref(breakpoints.smaller('sm'));

const props = defineProps({
  targetClass: {
    type: String,
    default: undefined,
  },

  theme: {
    type: String,
    default: undefined,
    validator(theme) {
      return ['fixed'].includes(theme);
    },
  },

  cursorMaskBgDesktop: {
    type: String,
    default: '/images/common/bg.webp',
  },

  cursorMaskBgMobile: {
    type: String,
    default: '/images/common/bg.webp',
  },
});

const rootRef = ref(null);
const parentElement = ref(null);
const cursorRef = ref(null);

onMounted(() => {
  parentElement.value = document.querySelector(`.${props.targetClass}`);

  if (!isSmBreakpoint.value && parentElement.value) {
    parentElement.value.addEventListener('mousemove', moveAnimation);
    parentElement.value.addEventListener('mouseleave', hiddenCursor);
  }
});

onBeforeUnmount(() => {
  if (parentElement.value) {
    parentElement.value.removeEventListener('mousemove', moveAnimation);
    parentElement.value.removeEventListener('mouseleave', hiddenCursor);
  }
});

function moveAnimation(e) {
  const { top, left } = parentElement.value.getBoundingClientRect();
  const [fixedTop, fixedLeft] = [top.toFixed(0), left.toFixed(0)];

  const mouseX = e.clientX - fixedLeft - window.innerWidth / 4;
  const mouseY = e.clientY - fixedTop - window.innerHeight / 4;

  // if (
  //   e.target.tagName === 'BUTTON' ||
  //   e.target?.parentElement?.tagName === 'BUTTON' ||
  //   e.target.tagName === 'A' ||
  //   e.target?.parentElement?.tagName === 'A'
  // ) {
  //   hiddenCursor();
  //
  //   return;
  // }

  $gsap.to(cursorRef.value, {
    maskPositionX: `${mouseX}px`,
    maskPositionY: `${mouseY}px`,
    opacity: 0.9,
    duration: 2,
    ease: 'power2.out',
  });
}

function hiddenCursor() {
  $gsap.to(cursorRef.value, {
    opacity: 0,
    duration: 2,
    ease: 'power2.out',
  });
}
</script>
<template>
  <div
    ref="rootRef"
    class="background-block"
    :class="{
      [`background-block--theme--${props.theme}`]: props.theme,
    }"
    :style="{
      ['--cursor-mask-bg-desktop']: `url(${props.cursorMaskBgDesktop})`,
      ['--cursor-mask-bg-mobile']: `url(${props.cursorMaskBgMobile})`,
    }"
  >
    <div class="background-block__background">
      <slot name="background-image">
        <img
          src="/images/common/bg1.webp"
          alt="background"
          class="background-block__image background-block__image--background-desktop"
        />

        <img
          src="/images/common/bg1.webp"
          alt="background"
          class="background-block__image background-block__image--background-mobile"
        />
      </slot>
    </div>

    <div class="background-block__mask">
      <div
        ref="cursorRef"
        class="background-block__image background-block__image--cursor"
      />
    </div>
  </div>
</template>
<style scoped lang="scss">
.background-block {
  position: absolute;
  top: 0;
  left: 0;
  z-index: -1;
  width: 100%;
  height: 100%;

  &__background {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }

  &__image {
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center;
    opacity: 0.9;

    &--cursor {
      $mask-scale: 1;
      --mask-width: #{(em(975 * $mask-scale))};
      --mask-height: #{(em(853 * $mask-scale))};

      position: absolute;
      z-index: 3;
      width: 100%;
      height: 100%;
      display: block;
      mask-image: url('#{$images-path}/common/bg-cursor.svg'); /* URL to your mask image */
      mask-size: var(--mask-width) var(--mask-height); /* Adjust according to your needs */
      mask-repeat: no-repeat; /* Adjust according to your needs */
      -webkit-mask-image: url('#{$images-path}/common/bg-cursor.svg'); /* For WebKit browsers */
      -webkit-mask-size: var(--mask-width) var(--mask-height); /* For WebKit browsers */
      -webkit-mask-repeat: no-repeat; /* For WebKit browsers */
      background-image: var(--cursor-mask-bg-desktop);
      background-position: right bottom;
      background-repeat: no-repeat;
      background-size: cover;
      opacity: 0;

      @include media-breakpoint-down(lg) {
        $mask-scale: 1.25;
        --mask-width: #{(em(975 * $mask-scale))};
        --mask-height: #{(em(853 * $mask-scale))};
        --x-offset: #{var(--mask-width) / 2};
        --y-offset: #{var(--mask-height) / 2};

        opacity: 1;
        mask-size: var(--mask-width) var(--mask-height); /* Adjust according to your needs */
        -webkit-mask-size: var(--mask-width) var(--mask-height); /* For WebKit browsers */

        @keyframes mask-move {
          0% {
            mask-position: calc(50vw - var(--x-offset))
              calc(120vh - var(--y-offset));
            -webkit-mask-position: calc(50vw - var(--x-offset))
              calc(120vh - var(--y-offset));
          }
          20% {
            mask-position: calc(80vw - var(--x-offset))
              calc(60vh - var(--y-offset));
            -webkit-mask-position: calc(80vw - var(--x-offset))
              calc(60vh - var(--y-offset));
          }
          40% {
            mask-position: calc(10vw - var(--x-offset))
              calc(10vh - var(--y-offset));
            -webkit-mask-position: calc(10vw - var(--x-offset))
              calc(10vh - var(--y-offset));
          }
          60% {
            mask-position: calc(110vw - var(--x-offset))
              calc(30vh - var(--y-offset));
            -webkit-mask-position: calc(110vw - var(--x-offset))
              calc(30vh - var(--y-offset));
          }
          80% {
            mask-position: calc(70vw - var(--x-offset))
              calc(90vh - var(--y-offset));
            -webkit-mask-position: calc(70vw - var(--x-offset))
              calc(90vh - var(--y-offset));
          }
          100% {
            mask-position: calc(0vw - var(--x-offset))
              calc(120vh - var(--y-offset));
            -webkit-mask-position: calc(0vw - var(--x-offset))
              calc(120vh - var(--y-offset));
          }
        }

        animation: mask-move 20s infinite;
      }

      @include media-breakpoint-down(sm) {
        background-image: var(--cursor-mask-bg-mobile);
      }
    }

    &--background-desktop {
      display: block;
      object-position: right bottom;
      object-fit: cover;
      opacity: 1;

      @include media-breakpoint-down(sm) {
        display: none;
      }
    }

    &--background-mobile {
      display: none;
      object-position: right bottom;
      object-fit: cover;
      opacity: 1;

      @include media-breakpoint-down(lg) {
        display: block;
      }
    }
  }

  &__mask {
  }

  &--theme {
    &--fixed {
      position: fixed;
      width: 100vw;
      height: 100vh;
    }
  }
}
</style>
