<template>
  <div id="app" class="app">
    <NuxtLayout v-slot="{ className }" class="app__layout">
      <NuxtPage :class="className" />
    </NuxtLayout>

    <ModalsContainer />
  </div>
</template>

<script setup>
import { watch } from '#imports';
import { ModalsContainer, useModal } from 'vue-final-modal';
import { useAuthStore } from '@/stores/auth';
import { useSurveyStore } from '@/stores/survey';
import { useJwt } from '@vueuse/integrations/useJwt';
import { useSessionEnded } from '@/composables/useSessionEnded';
import { useRouter, useCookie } from 'nuxt/app';

import Survey from '@/components/popups/Survey.vue';

const authStore = useAuthStore();
const surveyStore = useSurveyStore();
const router = useRouter();

//<editor-fold desc="SURVEY">
const surveyPopup = useModal({
  modalId: 'surveyPopup',
  component: Survey,
});
let intervalSurvey = null;
watch(
  () => authStore.isAuthorized,
  (value) => {
    if (intervalSurvey) {
      clearInterval(intervalSurvey);
      intervalSurvey = null;
    }

    if (process.server || !value) return;

    intervalSurvey = setInterval(() => {
      checkSurveys();
    }, 300000);
  },
  {
    immediate: true,
  },
);
function checkSurveys() {
  surveyStore
    .fetchSurveys()
    .then((data) => {
      const needToShowFirstSurvey = data?.consumption?.first;
      const needToShowSecondSurvey = data?.consumption?.second;
      const surveyCookie = useCookie('isSurvey');

      if (!needToShowFirstSurvey && !needToShowSecondSurvey) {
        clearInterval(intervalSurvey);
        intervalSurvey = null;

        return;
      }
      // Если попап закрывался без прохождения, то больше его не показываем в рамках одной сессии
      if (surveyCookie.value) {
        clearInterval(intervalSurvey);
        intervalSurvey = null;

        return;
      }

      const isSurveyPopupOpen = document.querySelector('.survey-popup');

      if (!isSurveyPopupOpen) {
        surveyPopup.patchOptions({
          attrs: {
            closeModal: () => {
              surveyPopup.close();
            },
            actionAfterCloseModal: () => {
              surveyStore.sendConsumptionClose();
            },
            onClosed() {
              surveyCookie.value = 'true';
            },
          },
        });

        surveyPopup.open();
      }
    })
    .catch(() => {
      clearInterval(intervalSurvey);
      intervalSurvey = null;
    });
}
//</editor-fold>

//<editor-fold desc="CHECK TOKEN">
let intervalCheckToken = null;
watch(
  () => authStore.accessToken,
  (value) => {
    if (intervalCheckToken) {
      clearInterval(intervalCheckToken);
      intervalCheckToken = null;
    }

    if (process.server || !value) return;

    intervalCheckToken = setInterval(() => {
      checkTokenExpiration();
    }, 30000);
  },
  {
    immediate: true,
  },
);
async function checkTokenExpiration() {
  const bufferTime = 5 * 60; // 5 минут в секундах
  const { payload } = useJwt(authStore.accessToken);
  const expTime = payload.value.exp;
  let currentTime = Math.floor(Date.now() / 1000);

  try {
    const serverTime = await authStore.fetchServerTime();

    currentTime = serverTime.time;
  } catch (e) {
    console.log(e);
  }

  const timeLeft = expTime - currentTime;

  if (timeLeft <= bufferTime) {
    if (timeLeft > 0) {
      authStore.refreshToken();
    } else {
      authStore.logout();

      surveyPopup?.close();

      const { openSessionEndedPopup } = useSessionEnded();

      openSessionEndedPopup({
        heading: 'Сеанс завершен',
        description:
          'Для продолжения работы с сайтом, пожалуйста, авторизуйтесь заново',
      });

      router.push('/auth/login');
    }
  }
}
//</editor-fold>
</script>

<style scoped lang="scss">
.app {
  $parent: &;

  display: flex;
  flex-direction: column;

  &:deep(#{$parent}__layout) {
    flex-grow: 1;
  }
}
</style>

