import { defineStore } from 'pinia';
import { useRequest } from '@/composables/useRequest';
export const useSurveyStore = defineStore({
  id: 'survey',
  state: () => ({
    firstSurvey: null,
    secondSurvey: null,
  }),
  getters: {},
  actions: {
    fetchMarks() {
      return useRequest('/marks', {
        method: 'GET',
      }).then(({ data }) => {
        return data;
      });
    },
    sendConsumptionFirst(data = {}) {
      return useRequest('/surveys/consumption/first', {
        method: 'POST',
        body: data,
      });
    },
    sendConsumptionSecond(data = {}) {
      return useRequest('/surveys/consumption/second', {
        method: 'POST',
        body: data,
      });
    },
    sendConsumptionClose() {
      return useRequest('/surveys/close', {
        method: 'POST',
      });
    },
    fetchSurveys() {
      return useRequest('/surveys', {
        method: 'GET',
      }).then(({ data }) => {
        this.firstSurvey = data?.consumption?.first;
        this.secondSurvey = data?.consumption?.second;

        return data;
      });
    },
  },
});
