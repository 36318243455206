<script setup>
import {
  computed,
  defineAsyncComponent,
  reactive,
  ref,
  onMounted,
  watch,
} from '#imports';
import { useSurveyStore } from '@/stores/survey';
import { useInfoPopup } from '@/composables/useInfoPopup';
import { useFormHelp } from '@/composables/useFormHelp';
import { useAuthStore } from '@/stores/auth';

import PopupCenter from '@/components/layout/popup/PopupCenter.vue';
import SurveyStartForm from '@/components/forms/survey/SurveyStartForm.vue';

const surveyStore = useSurveyStore();
const { openInfoPopup } = useInfoPopup();
const formHelpComposable = useFormHelp();
const authStore = useAuthStore();

const props = defineProps({
  closeModal: {
    type: Function,
    default: undefined,
  },
  actionAfterCloseModal: {
    type: Function,
    default: undefined,
  },
});

const actionButtonCloseOnPopup = computed(() => {
  if (!props.actionAfterCloseModal) return undefined;

  return () => {
    props.actionAfterCloseModal?.();
  };
});

watch(
  () => authStore.isAuthorized,
  (value) => {
    if (!value) {
      props.closeModal?.();
    }
  },
);

// <editor-fold desc="Steps">
const typesDictionary = {
  start: 'start',
  survey: 'survey',
  finish: 'finish',
};
const currentStep = ref(typesDictionary.start);
const isNeedToShowStart = computed(() => {
  return surveyStore.firstSurvey;
});
const isNeedToShowSurvey = computed(() => {
  return surveyStore.secondSurvey;
});

onMounted(() => {
  if (isNeedToShowStart.value === false) {
    currentStep.value = typesDictionary.survey;
  }
});

const steps = computed(() => {
  return {
    [typesDictionary.start]: {
      component: {
        value: SurveyStartForm,
        data: {
          heading: 'Опрос',
          description: 'Курите ли Вы сигареты?',
          buttonData: [
            {
              text: 'Да',
              action: isSmoke,
            },
            {
              text: 'Нет',
              action: notSmoke,
              theme: 'second',
            },
          ],
        },
      },
      steps: {
        index: 1,
        next: {
          value: typesDictionary.survey,
        },
      },
    },
    [typesDictionary.survey]: {
      component: {
        value: defineAsyncComponent(
          () => import('@/components/forms/survey/SurveyForm.vue'),
        ),
        data: {
          submitForm: sendForm,
        },
      },
      steps: {
        index: 2,
        next: {
          value: typesDictionary.finish,
        },
      },
    },
    [typesDictionary.finish]: {
      component: {
        value: defineAsyncComponent(
          () => import('@/components/forms/survey/StatusForm.vue'),
        ),
        data: {
          heading: 'Спасибо!',
          description: 'Благодарим за участие в опросе!',
        },
      },
      steps: {
        index: 3,
        next: {
          value: '',
        },
      },
    },
  };
});
const currentStepData = computed(() => {
  return steps.value[currentStep.value];
});
const currentComponent = computed(() => {
  return currentStepData.value?.component;
});
const currentComponentData = computed(() => {
  return currentComponent.value?.data;
});

function changeStep(step) {
  const isValid = Object.keys(steps.value).includes(step);

  if (!isValid) return;

  currentStep.value = typesDictionary[step];
}
// </editor-fold>

// <editor-fold desc="FORM">
const formData = reactive({
  brand: null,
  primaryCigaretteSubfamily: null,
  cigaretteCountPerDayField: '',
  primaryBrandAgeField: null,
  primaryBrandShareField: null,
  brandAll: null,
  secondaryCigaretteSubfamily: null,
});
watch(
  () => formData.brand,
  () => {
    formData.primaryCigaretteSubfamily = null;
  },
);
watch(
  () => formData.brandAll,
  () => {
    formData.secondaryCigaretteSubfamily = null;
  },
);
function updateForm({ key, value }) {
  formData[key] = value;
}
function isSmoke() {
  surveyStore
    .sendConsumptionFirst({ smokeFlag: true })
    .then(() => {
      if (isNeedToShowSurvey.value || isNeedToShowSurvey.value === null) {
        changeStep(typesDictionary.survey);
      } else {
        changeStep(typesDictionary.finish);
      }
    })
    .catch(({ data }) => {
      openInfoPopup({
        title: 'Ошибка',
        description: data.message,
      });
    });
}
function notSmoke() {
  surveyStore
    .sendConsumptionFirst({ smokeFlag: false })
    .then(() => {
      props.closeModal?.();
    })
    .catch(({ data }) => {
      openInfoPopup({
        title: 'Ошибка',
        description: data.message,
      });
    });
}
function sendForm(values, actions) {
  const data = {
    primaryCigaretteSubfamily: values.primaryCigaretteSubfamily?.spicId,
    cigaretteCountPerDayField: values.cigaretteCountPerDayField,
    primaryBrandAgeField: values.primaryBrandAgeField.name,
    primaryBrandShareField: values.primaryBrandShareField.name,
    secondaryCigaretteSubfamily: values.secondaryCigaretteSubfamily?.spicId,
  };
  surveyStore
    .sendConsumptionSecond(data)
    .then(() => {
      changeStep(typesDictionary.finish);
    })
    .catch(({ data }) => {
      formHelpComposable.checkErrors(data, actions.setErrors);
    });
}
// </editor-fold>
</script>
<template>
  <PopupCenter
    full-width-mobile
    :action-button-close="actionButtonCloseOnPopup"
  >
    <template #default>
      <div class="survey-popup">
        <div class="survey-popup__content">
          <transition name="fade" mode="out-in">
            <component
              :is="currentComponent.value"
              v-if="currentComponent?.value"
              :key="currentStep"
              :form-data="formData"
              :component-data="currentComponentData"
              class="survey-popup__component"
              @update-form="updateForm"
            />
          </transition>
        </div>
      </div>
    </template>
  </PopupCenter>
</template>
<style scoped lang="scss"></style>
