// to, from, savedPosition
function handle(to, from, savedPosition) {
  if (savedPosition) return savedPosition;

  if (to.hash) {
    return { el: to.hash };
  }

  if (to.name === 'products-line' && from.name === 'products-line') {
    return null;
  }

  return to.fullPath !== from.fullPath ? { top: 0 } : null;
}

/**
 * @see https://router.vuejs.org/api/interfaces/routeroptions.html
 */
export default {
  scrollBehavior(to, from, savedPosition) {
    return handle(to, from, savedPosition);
  },
};
