export const ksBlueSequence = Array.from(
  { length: 72 },
  (_, i) =>
    `/images/products/ks/blue/sequence/1300${i + 1 < 10 ? `0${i + 1}` : i + 1}.webp`,
);

export const ksRedSequence = Array.from(
  { length: 72 },
  (_, i) =>
    `/images/products/ks/red/sequence/500${i + 1 < 10 ? `0${i + 1}` : i + 1}.webp`,
);

export const ksSilverSequence = Array.from(
  { length: 72 },
  (_, i) =>
    `/images/products/ks/silver/sequence/800${i + 1 < 10 ? `0${i + 1}` : i + 1}.webp`,
);

export const ssBlueSequence = Array.from(
  { length: 72 },
  (_, i) =>
    `/images/products/ss/blue/sequence/0300${i + 1 < 10 ? `0${i + 1}` : i + 1}.webp`,
);

export const ssMentholSequence = Array.from(
  { length: 72 },
  (_, i) =>
    `/images/products/ss/menthol/sequence/1400${i + 1 < 10 ? `0${i + 1}` : i + 1}.webp`,
);

export const ssSilverSequence = Array.from(
  { length: 72 },
  (_, i) =>
    `/images/products/ss/silver/sequence/1100${i + 1 < 10 ? `0${i + 1}` : i + 1}.webp`,
);

export const xstyleSilverSequence = Array.from(
  { length: 72 },
  (_, i) =>
    `/images/products/xstyle/silver/sequence/100${i + 1 < 10 ? `0${i + 1}` : i + 1}.webp`,
);

export const xstyleBlueSequence = Array.from(
  { length: 72 },
  (_, i) =>
    `/images/products/xstyle/blue/sequence/100${i + 1 < 10 ? `0${i + 1}` : i + 1}.webp`,
);

export const xstyleDualSequence = Array.from(
  { length: 72 },
  (_, i) =>
    `/images/products/xstyle/dual/sequence/13_000${i + 1 < 10 ? `0${i + 1}` : i + 1}.webp`,
);

export const kissCosmoSequence = Array.from(
  { length: 72 },
  (_, i) =>
    `/images/products/kiss/cosmo/sequence/100${i + 1 < 10 ? `0${i + 1}` : i + 1}.webp`,
);

export const kissDreamSequence = Array.from(
  { length: 72 },
  (_, i) =>
    `/images/products/kiss/dream/sequence/100${i + 1 < 10 ? `0${i + 1}` : i + 1}.webp`,
);

export const kissGlamSequence = Array.from(
  { length: 72 },
  (_, i) =>
    `/images/products/kiss/glam/sequence/100${i + 1 < 10 ? `0${i + 1}` : i + 1}.webp`,
);

export const kissJollySequence = Array.from(
  { length: 72 },
  (_, i) =>
    `/images/products/kiss/jolly/sequence/100${i + 1 < 10 ? `0${i + 1}` : i + 1}.webp`,
);

export const kissMirageSequence = Array.from(
  { length: 72 },
  (_, i) =>
    `/images/products/kiss/mirage/sequence/100${i + 1 < 10 ? `0${i + 1}` : i + 1}.webp`,
);

export const kissMentholSequence = Array.from(
  { length: 72 },
  (_, i) =>
    `/images/products/kiss/menthol/sequence/100${i + 1 < 10 ? `0${i + 1}` : i + 1}.webp`,
);

export const compactElectroSequence = Array.from(
  { length: 72 },
  (_, i) =>
    `/images/products/compact/electro/sequence/100${i + 1 < 10 ? `0${i + 1}` : i + 1}.webp`,
);

export const compactBlueSequence = Array.from(
  { length: 72 },
  (_, i) =>
    `/images/products/compact/blue/sequence/100${i + 1 < 10 ? `0${i + 1}` : i + 1}.webp`,
);

export const compactFrozenSequence = Array.from(
  { length: 72 },
  (_, i) =>
    `/images/products/compact/frozen/sequence/100${i + 1 < 10 ? `0${i + 1}` : i + 1}.webp`,
);

export const compact100BlueSequence = Array.from(
  { length: 72 },
  (_, i) =>
    `/images/products/compact/100-blue/sequence/100${i + 1 < 10 ? `0${i + 1}` : i + 1}.webp`,
);

export const compactCostaSequence = Array.from(
  { length: 72 },
  (_, i) =>
    `/images/products/compact/costa/sequence/100${i + 1 < 10 ? `0${i + 1}` : i + 1}.webp`,
);

export const xsArcticSequence = Array.from(
  { length: 72 },
  (_, i) =>
    `/images/products/xs/arctic/sequence/100${i + 1 < 10 ? `0${i + 1}` : i + 1}.webp`,
);

export const xsBlueSequence = Array.from(
  { length: 72 },
  (_, i) =>
    `/images/products/xs/blue/sequence/100${i + 1 < 10 ? `0${i + 1}` : i + 1}.webp`,
);

export const xsImpulseSequence = Array.from(
  { length: 72 },
  (_, i) =>
    `/images/products/xs/impulse/sequence/100${i + 1 < 10 ? `0${i + 1}` : i + 1}.webp`,
);

export const xsSilverSequence = Array.from(
  { length: 72 },
  (_, i) =>
    `/images/products/xs/silver/sequence/100${i + 1 < 10 ? `0${i + 1}` : i + 1}.webp`,
);
