import {
  compact100BlueSequence,
  compactBlueSequence,
  compactCostaSequence,
  compactElectroSequence,
  compactFrozenSequence,
  kissCosmoSequence,
  kissDreamSequence,
  kissGlamSequence,
  kissJollySequence,
  kissMentholSequence,
  kissMirageSequence,
  ksBlueSequence,
  ksRedSequence,
  ksSilverSequence,
  ssBlueSequence,
  ssMentholSequence,
  ssSilverSequence,
  xsArcticSequence,
  xsBlueSequence,
  xsImpulseSequence,
  xsSilverSequence,
  xstyleBlueSequence,
  xstyleDualSequence,
  xstyleSilverSequence,
} from '@/configs/products-sequences';

const products = [
  {
    line: {
      label: 'king size',
      alias: 'king-size',
      id: 'king-size',
      description: 'премиальная табачная мешка в классическом формате',
      defaultLineProduct: 'king-size-blue',
    },
    list: [
      {
        id: 'king-size-blue',
        brand: 'winston',
        brakeAfterBrand: true,
        line: {
          label: 'king size',
          alias: 'king-size',
        },
        type: {
          label: 'blue',
          alias: 'blue',
        },
        showLine: false,
        price: '233 ₽',
        consistency: {
          resine: {
            min: '0',
            max: '10',
            value: '6',
            unit: 'МГ/СИГ**',
            label: 'смола',
          },
          nicotine: {
            min: '0',
            max: '1',
            value: '0.5',
            unit: 'МГ/СИГ**',
            label: 'никотин',
          },
        },
        materials: {
          packOn: {
            src: '/images/products/ks/blue/pack-on.webp',
          },
          packOffReflection: {
            src: '/images/products/ks/blue/pack-off-reflection.webp',
          },
          packOff: {
            src: '/images/products/ks/blue/pack-off.webp',
          },
          cig: {
            src: '/images/products/ks/blue/cig.webp',
          },
          backgroundDesktop: {
            src: '/images/products/common/backgrounds/background-ks-blue-desktop.webp',
          },
          backgroundMobile: {
            src: '/images/products/common/backgrounds/background-ks-blue-mobile.webp',
          },
          mainPage: {
            desktop: {
              src: '/images/products/main-page/winston-king-size-desktop.webp',
            },
            mobile: {
              src: '/images/products/main-page/winston-king-size-mobile.webp',
            },
            bgBlur: {
              src: '/images/products/main-page/bg-blur.svg',
              srcMob: '/images/products/main-page/bg-blur-mb.svg',
            },
          },
        },
        characteristics: [
          {
            image: {
              src: '/images/products/common/characteristics/filter-ks.webp',
            },
            backgroundColor: 'char-blue',
            title: 'Уплотненный<br/> моноацетатный<br/> фильтр',
            // description:
            //   'Инновационная технология<br/> для сохранения формы<br/> сигарет',
          },
          {
            image: {
              src: '/images/products/common/characteristics/tobacco.webp',
            },
            backgroundColor: 'char-brown',
            title: 'Премиальная<br/> табачная<br/> мешка',
            // description:
            //   'Собранные вручную<br/> высококачественные сорта<br/> табака для насыщенного<br/> вкуса',
          },
        ],
        promo: {
          banner: {
            desktop: {
              src: '/images/products/common/banners/banner-ks-blue-desktop.webp',
            },
            mobile: {
              src: '/images/products/common/banners/banner-ks-blue-mobile.webp',
            },
          },
          cigPosition: 1,
          titleParts: [
            {
              text: 'регулярный',
              perspective: 'top',
              size: 'm',
            },
            {
              text: 'формат',
              perspective: 'bottom',
              size: 'm',
            },
          ],
        },
        sequence: {
          platform: {
            src: '/images/products/common/platforms/platform-ks-blue.webp',
          },
          platformShadow: {
            src: '/images/products/common/platforms/shadow/platform-ks-blue.webp',
          },
          items: ksBlueSequence,
        },
        palette: {
          backgroundEllipseColor: '#77C3FA',
        },
      },
      {
        id: 'king-size-classic',
        brand: 'winston',
        brakeAfterBrand: true,
        line: {
          label: 'king size',
          alias: 'king-size',
        },
        type: {
          label: 'classic',
          alias: 'classic',
        },
        showLine: false,
        price: '233 ₽',
        consistency: {
          resine: {
            min: '0',
            max: '10',
            value: '9',
            unit: 'МГ/СИГ**',
            label: 'смола',
          },
          nicotine: {
            min: '0',
            max: '1',
            value: '0.7',
            unit: 'МГ/СИГ**',
            label: 'никотин',
          },
        },
        materials: {
          packOn: {
            src: '/images/products/ks/red/pack-on.webp',
          },
          packOffReflection: {
            src: '/images/products/ks/red/pack-off-reflection.webp',
          },
          packOff: {
            src: '/images/products/ks/red/pack-off.webp',
          },
          cig: {
            src: '/images/products/ks/red/cig.webp',
          },
          backgroundDesktop: {
            src: '/images/products/common/backgrounds/background-ks-blue-desktop.webp',
          },
          backgroundMobile: {
            src: '/images/products/common/backgrounds/background-ks-blue-mobile.webp',
          },
          mainPage: {
            desktop: {
              src: '/images/products/main-page/winston-king-size-desktop.webp',
            },
            mobile: {
              src: '/images/products/main-page/winston-king-size-mobile.webp',
            },
            bgBlur: {
              src: '/images/products/main-page/bg-blur.svg',
              srcMob: '/images/products/main-page/bg-blur-mb.svg',
            },
          },
        },
        characteristics: [
          {
            image: {
              src: '/images/products/common/characteristics/filter-ks.webp',
            },
            backgroundColor: 'char-blue',
            title: 'Уплотненный<br/> моноацетатный<br/> фильтр',
            // description:
            //   'Инновационная технология<br/> для сохранения формы<br/> сигарет',
          },
          {
            image: {
              src: '/images/products/common/characteristics/tobacco.webp',
            },
            backgroundColor: 'char-brown',
            title: 'Премиальная<br/> табачная<br/> мешка',
            // description:
            //   'Собранные вручную<br/> высококачественные сорта<br/> табака для насыщенного<br/> вкуса',
          },
        ],
        promo: {
          banner: {
            desktop: {
              src: '/images/products/common/banners/banner-ks-blue-desktop.webp',
            },
            mobile: {
              src: '/images/products/common/banners/banner-ks-blue-mobile.webp',
            },
          },
          cigPosition: 1,
          titleParts: [
            {
              text: 'регулярный',
              perspective: 'top',
              size: 'm',
            },
            {
              text: 'формат',
              perspective: 'bottom',
              size: 'm',
            },
          ],
        },
        sequence: {
          platform: {
            src: '/images/products/common/platforms/platform-ks-blue.webp',
          },
          platformShadow: {
            src: '/images/products/common/platforms/shadow/platform-ks-blue.webp',
          },
          items: ksRedSequence,
        },
        palette: {
          backgroundEllipseColor: '#77C3FA',
        },
      },
      {
        id: 'king-size-silver',
        brand: 'winston',
        brakeAfterBrand: true,
        line: {
          label: 'king size',
          alias: 'king-size',
        },
        type: {
          label: 'silver',
          alias: 'silver',
        },
        showLine: false,
        price: '233 ₽',
        consistency: {
          resine: {
            min: '0',
            max: '10',
            value: '3',
            unit: 'МГ/СИГ**',
            label: 'смола',
          },
          nicotine: {
            min: '0',
            max: '1',
            value: '0.3',
            unit: 'МГ/СИГ**',
            label: 'никотин',
          },
        },
        materials: {
          packOn: {
            src: '/images/products/ks/silver/pack-on.webp',
          },
          packOffReflection: {
            src: '/images/products/ks/silver/pack-off-reflection.webp',
          },
          packOff: {
            src: '/images/products/ks/silver/pack-off.webp',
          },
          cig: {
            src: '/images/products/ks/silver/cig.webp',
          },
          backgroundDesktop: {
            src: '/images/products/common/backgrounds/background-ks-blue-desktop.webp',
          },
          backgroundMobile: {
            src: '/images/products/common/backgrounds/background-ks-blue-mobile.webp',
          },
          mainPage: {
            desktop: {
              src: '/images/products/main-page/winston-king-size-desktop.webp',
            },
            mobile: {
              src: '/images/products/main-page/winston-king-size-mobile.webp',
            },
            bgBlur: {
              src: '/images/products/main-page/bg-blur.svg',
              srcMob: '/images/products/main-page/bg-blur-mb.svg',
            },
          },
        },
        characteristics: [
          {
            image: {
              src: '/images/products/common/characteristics/filter-ks.webp',
            },
            backgroundColor: 'char-blue',
            title: 'Уплотненный<br/> моноацетатный<br/> фильтр',
            // description:
            //   'Инновационная технология<br/> для сохранения формы<br/> сигарет',
          },
          {
            image: {
              src: '/images/products/common/characteristics/tobacco.webp',
            },
            backgroundColor: 'char-brown',
            title: 'Премиальная<br/> табачная<br/> мешка',
            // description:
            //   'Собранные вручную<br/> высококачественные сорта<br/> табака для насыщенного<br/> вкуса',
          },
        ],
        promo: {
          banner: {
            desktop: {
              src: '/images/products/common/banners/banner-ks-blue-desktop.webp',
            },
            mobile: {
              src: '/images/products/common/banners/banner-ks-blue-mobile.webp',
            },
          },
          cigPosition: 1,
          titleParts: [
            {
              text: 'регулярный',
              perspective: 'top',
              size: 'm',
            },
            {
              text: 'формат',
              perspective: 'bottom',
              size: 'm',
            },
          ],
        },
        sequence: {
          platform: {
            src: '/images/products/common/platforms/platform-ks-blue.webp',
          },
          platformShadow: {
            src: '/images/products/common/platforms/shadow/platform-ks-blue.webp',
          },
          items: ksSilverSequence,
        },
        palette: {
          backgroundEllipseColor: '#77C3FA',
        },
      },
    ],
  },
  {
    line: {
      label: 'super slims',
      alias: 'super-slims',
      id: 'super-slims',
      description: 'премиальная табачная мешка в супертонком формате',
      defaultLineProduct: 'super-slims-blue',
    },
    list: [
      {
        id: 'super-slims-blue',
        brand: 'winston',
        brakeAfterBrand: true,
        line: {
          label: 'super slims',
          alias: 'super-slims',
        },
        type: {
          label: 'blue',
          alias: 'blue',
        },
        showLine: true,
        price: '233 ₽',
        consistency: {
          resine: {
            min: '0',
            max: '10',
            value: '5',
            unit: 'МГ/СИГ**',
            label: 'смола',
          },
          nicotine: {
            min: '0',
            max: '1',
            value: '0.5',
            unit: 'МГ/СИГ**',
            label: 'никотин',
          },
        },
        materials: {
          packOn: {
            src: '/images/products/ss/blue/pack-on.webp',
          },
          packOffReflection: {
            src: '/images/products/ss/blue/pack-off-reflection.webp',
          },
          packOff: {
            src: '/images/products/ss/blue/pack-off.webp',
          },
          cig: {
            src: '/images/products/ss/blue/cig.webp',
          },
          backgroundDesktop: {
            src: '/images/products/common/backgrounds/background-ss-blue-desktop.webp',
          },
          backgroundMobile: {
            src: '/images/products/common/backgrounds/background-ss-blue-mobile.webp',
          },
          mainPage: {
            desktop: {
              src: '/images/products/main-page/winston-super-slims-blue-desktop.webp',
            },
            mobile: {
              src: '/images/products/main-page/winston-super-slims-blue-mobile.webp',
            },
            bgBlur: {
              src: '/images/products/main-page/bg-blur.svg',
              srcMob: '/images/products/main-page/bg-blur-mb.svg',
            },
          },
        },
        characteristics: [
          {
            image: {
              src: '/images/products/common/characteristics/filter-ss-kiss.webp',
            },
            backgroundColor: 'char-blue-ultramarine-1',
            title: 'моноацетатный<br/> фильтр',
            // description:
            //   'Обеспечивает<br/> сбалансированный<br/> и глубокий вкус',
          },
          {
            image: {
              src: '/images/products/common/characteristics/tobacco.webp',
            },
            backgroundColor: 'char-beige',
            title: 'Премиальная<br/> табачная<br/> мешка',
            // description:
            //   'Собранные вручную<br/> высококачественные сорта<br/> табака для насыщенного<br/> вкуса',
          },
        ],
        promo: {
          banner: {
            desktop: {
              src: '/images/products/common/banners/banner-ss-blue-desktop.webp',
            },
            mobile: {
              src: '/images/products/common/banners/banner-ss-blue-mobile.webp',
            },
          },
          cigPosition: 2,
          titleParts: [
            {
              text: 'супертонкий',
              perspective: 'top',
              size: 'm',
            },
            {
              text: 'формат',
              perspective: 'bottom',
              size: 'm',
            },
          ],
        },
        sequence: {
          platform: {
            src: '/images/products/common/platforms/platform-ss-blue.webp',
          },
          platformShadow: {
            src: '/images/products/common/platforms/shadow/platform-ss-blue.webp',
          },
          items: ssBlueSequence,
        },
        palette: {
          backgroundEllipseColor: '#74C0F6',
        },
      },
      {
        id: 'super-slims-silver',
        brand: 'winston',
        brakeAfterBrand: true,
        line: {
          label: 'super slims',
          alias: 'super-slims',
        },
        type: {
          label: 'silver',
          alias: 'silver',
        },
        showLine: true,
        price: '233 ₽',
        consistency: {
          resine: {
            min: '0',
            max: '10',
            value: '2',
            unit: 'МГ/СИГ**',
            label: 'смола',
          },
          nicotine: {
            min: '0',
            max: '1',
            value: '0.2',
            unit: 'МГ/СИГ**',
            label: 'никотин',
          },
        },
        materials: {
          packOn: {
            src: '/images/products/ss/silver/pack-on.webp',
          },
          packOffReflection: {
            src: '/images/products/ss/silver/pack-off-reflection.png',
          },
          packOff: {
            src: '/images/products/ss/silver/pack-off.webp',
          },
          cig: {
            src: '/images/products/ss/silver/cig.webp',
          },
          backgroundDesktop: {
            src: '/images/products/common/backgrounds/background-ss-silver-desktop.webp',
          },
          backgroundMobile: {
            src: '/images/products/common/backgrounds/background-ss-silver-mobile.webp',
          },
          mainPage: {
            desktop: {
              src: '/images/products/main-page/winston-super-slims-silver-desktop.webp',
            },
            mobile: {
              src: '/images/products/main-page/winston-super-slims-silver-mobile.webp',
            },
            bgBlur: {
              src: '/images/products/main-page/bg-blur.svg',
              srcMob: '/images/products/main-page/bg-blur-mb.svg',
            },
          },
        },
        characteristics: [
          {
            image: {
              src: '/images/products/common/characteristics/filter-ss-kiss.webp',
            },
            backgroundColor: 'char-blue-ultramarine-1',
            title: 'моноацетатный<br/> фильтр',
            // description:
            //   'Обеспечивает<br/> сбалансированный<br/> и глубокий вкус',
          },
          {
            image: {
              src: '/images/products/common/characteristics/tobacco.webp',
            },
            backgroundColor: 'char-beige',
            title: 'Премиальная<br/> табачная<br/> мешка',
            // description:
            //   'Собранные вручную<br/> высококачественные сорта<br/> табака для насыщенного<br/> вкуса',
          },
        ],
        promo: {
          banner: {
            desktop: {
              src: '/images/products/common/banners/banner-ss-silver-desktop.webp',
            },
            mobile: {
              src: '/images/products/common/banners/banner-ss-silver-mobile.webp',
            },
          },
          cigPosition: 2,
          titleParts: [
            {
              text: 'супертонкий',
              perspective: 'top',
              size: 'm',
            },
            {
              text: 'формат',
              perspective: 'bottom',
              size: 'm',
            },
          ],
        },
        sequence: {
          platform: {
            src: '/images/products/common/platforms/platform-ss-silver.webp',
          },
          platformShadow: {
            src: '/images/products/common/platforms/shadow/platform-ss-silver.webp',
          },
          items: ssSilverSequence,
        },
        palette: {
          backgroundEllipseColor: '#BAEDFF',
        },
      },
      {
        id: 'super-slims-fresh-menthol',
        brand: 'winston',
        brakeAfterBrand: true,
        line: {
          label: 'super slims',
          alias: 'super-slims',
        },
        type: {
          label: 'fresh menthol',
          alias: 'fresh-menthol',
        },
        showLine: true,
        price: '233 ₽',
        consistency: {
          resine: {
            min: '0',
            max: '10',
            value: '5',
            unit: 'МГ/СИГ**',
            label: 'смола',
          },
          nicotine: {
            min: '0',
            max: '1',
            value: '0.5',
            unit: 'МГ/СИГ**',
            label: 'никотин',
          },
        },
        materials: {
          packOn: {
            src: '/images/products/ss/menthol/pack-on.webp',
          },
          packOffReflection: {
            src: '/images/products/ss/menthol/pack-off-reflection.png',
          },
          packOff: {
            src: '/images/products/ss/menthol/pack-off.webp',
          },
          cig: {
            src: '/images/products/ss/menthol/cig.webp',
          },
          backgroundDesktop: {
            src: '/images/products/common/backgrounds/background-ss-menthol-desktop.webp',
          },
          backgroundMobile: {
            src: '/images/products/common/backgrounds/background-ss-menthol-mobile.webp',
          },
          mainPage: {
            desktop: {
              src: '/images/products/main-page/winston-super-slims-fresh-menthol-desktop.webp',
            },
            mobile: {
              src: '/images/products/main-page/winston-super-slims-fresh-menthol-mobile.webp',
            },
            bgBlur: {
              src: '/images/products/main-page/bg-blur.svg',
              srcMob: '/images/products/main-page/bg-blur-mb.svg',
            },
          },
        },
        characteristics: [
          {
            image: {
              src: '/images/products/common/characteristics/filter-ss-kiss.webp',
            },
            backgroundColor: 'char-blue-ultramarine-1',
            title: 'моноацетатный<br/> фильтр',
            // description:
            //   'Обеспечивает<br/> сбалансированный<br/> и глубокий вкус',
          },
          {
            image: {
              src: '/images/products/common/characteristics/tobacco.webp',
            },
            backgroundColor: 'char-beige',
            title: 'Премиальная<br/> табачная<br/> мешка',
            // description:
            //   'Собранные вручную<br/> высококачественные сорта<br/> табака для насыщенного<br/> вкуса',
          },
          {
            image: {
              src: '/images/products/common/characteristics/menthol.webp',
            },
            backgroundColor: 'char-mint',
            title: 'ментоловый<br/> вкус',
            // description: 'Вкус ментола за счет <br/> особой фольги в пачке',
          },
        ],
        promo: {
          banner: {
            desktop: {
              src: '/images/products/common/banners/banner-ss-fresh-menthol-desktop.webp',
            },
            mobile: {
              src: '/images/products/common/banners/banner-ss-fresh-menthol-desktop.webp',
            },
          },
          cigPosition: 2,
          titleParts: [
            {
              text: 'супертонкий',
              perspective: 'top',
              size: 'm',
            },
            {
              text: 'формат',
              perspective: 'bottom',
              size: 'm',
            },
          ],
        },
        sequence: {
          platform: {
            src: '/images/products/common/platforms/platform-ss-menthol.webp',
          },
          platformShadow: {
            src: '/images/products/common/platforms/shadow/platform-ss-menthol.webp',
          },
          items: ssMentholSequence,
        },
        palette: {
          backgroundEllipseColor: '#BAEDFF',
        },
      },
    ],
  },
  {
    line: {
      label: 'xstyle',
      alias: 'xstyle',
      id: 'xstyle',
      description:
        'сочетание премиальной мешки табака и уплотненного угольного фильтра',
      defaultLineProduct: 'xstyle-blue',
    },
    list: [
      {
        id: 'xstyle-blue',
        brand: 'winston',
        brakeAfterBrand: false,
        line: {
          label: 'xstyle',
          alias: 'xstyle',
        },
        type: {
          label: 'blue',
          alias: 'blue',
        },
        showLine: true,
        price: '202 ₽',
        consistency: {
          resine: {
            min: '0',
            max: '10',
            value: '6',
            unit: 'МГ/СИГ**',
            label: 'смола',
          },
          nicotine: {
            min: '0',
            max: '1',
            value: '0.5',
            unit: 'МГ/СИГ**',
            label: 'никотин',
          },
        },
        materials: {
          packOn: {
            src: '/images/products/xstyle/blue/pack-on.webp',
          },
          packOffReflection: {
            src: '/images/products/xstyle/blue/pack-off-reflection.webp',
          },
          packOff: {
            src: '/images/products/xstyle/blue/pack-off.webp',
          },
          cig: {
            src: '/images/products/xstyle/blue/cig.webp',
          },
          backgroundDesktop: {
            src: '/images/products/common/backgrounds/background-xstyle-blue-desktop.webp',
          },
          backgroundMobile: {
            src: '/images/products/common/backgrounds/background-xstyle-blue-mobile.webp',
          },
          mainPage: {
            desktop: {
              src: '/images/products/main-page/winston-xstyle-blue-desktop.webp',
            },
            mobile: {
              src: '/images/products/main-page/winston-xstyle-blue-mobile.webp',
            },
            bgBlur: {
              src: '/images/products/main-page/bg-blur.svg',
              srcMob: '/images/products/main-page/bg-blur-mb.svg',
            },
          },
        },
        characteristics: [
          {
            image: {
              src: '/images/products/common/characteristics/tobacco.webp',
            },
            backgroundColor: 'char-brown-light',
            title: 'Премиальная<br/> табачная<br/> мешка',
            // description:
            //   'Собранные вручную<br/> высококачественные сорта<br/> табака для насыщенного<br/> вкуса',
          },
          {
            image: {
              src: '/images/products/common/characteristics/filter-xstyle-compact.webp',
            },
            backgroundColor: 'char-grey',
            title: 'Угольный фильтр<br/> с рельефным<br/> покрытием',
            // description:
            //   'Сбалансированный вкус<br/> и дополнительная система<br/> фильтрации ',
          },
        ],
        promo: {
          banner: {
            desktop: {
              src: '/images/products/common/banners/banner-xstyle-blue-desktop.webp',
            },
            mobile: {
              src: '/images/products/common/banners/banner-xstyle-blue-mobile.webp',
            },
          },
          cigPosition: 2,
          titleParts: [
            {
              text: 'компактный',
              perspective: 'top',
              size: 'm',
            },
            {
              text: 'формат',
              perspective: 'bottom',
              size: 'm',
            },
          ],
        },
        sequence: {
          platform: {
            src: '/images/products/common/platforms/platform-xstyle-blue.webp',
          },
          platformShadow: {
            src: '/images/products/common/platforms/shadow/platform-xstyle-blue.webp',
          },
          items: xstyleBlueSequence,
        },
        palette: {
          backgroundEllipseColor: '#244470',
        },
      },
      {
        id: 'xstyle-silver',
        brand: 'winston',
        brakeAfterBrand: false,
        line: {
          label: 'xstyle',
          alias: 'xstyle',
        },
        type: {
          label: 'silver',
          alias: 'silver',
        },
        showLine: true,
        price: '202 ₽',
        consistency: {
          resine: {
            min: '0',
            max: '10',
            value: '4',
            unit: 'МГ/СИГ**',
            label: 'смола',
          },
          nicotine: {
            min: '0',
            max: '1',
            value: '0.3',
            unit: 'МГ/СИГ**',
            label: 'никотин',
          },
        },
        materials: {
          packOn: {
            src: '/images/products/xstyle/silver/pack-on.webp',
          },
          packOffReflection: {
            src: '/images/products/xstyle/silver/pack-off-reflection.webp',
          },
          packOff: {
            src: '/images/products/xstyle/silver/pack-off.webp',
          },
          cig: {
            src: '/images/products/xstyle/silver/cig.webp',
          },
          backgroundDesktop: {
            src: '/images/products/common/backgrounds/background-xstyle-silver-desktop.webp',
          },
          backgroundMobile: {
            src: '/images/products/common/backgrounds/background-xstyle-silver-mobile.webp',
          },
          mainPage: {
            desktop: {
              src: '/images/products/main-page/winston-xstyle-silver-desktop.webp',
            },
            mobile: {
              src: '/images/products/main-page/winston-xstyle-silver-mobile.webp',
            },
            bgBlur: {
              src: '/images/products/main-page/bg-blur.svg',
              srcMob: '/images/products/main-page/bg-blur-mb.svg',
            },
          },
        },
        characteristics: [
          {
            image: {
              src: '/images/products/common/characteristics/tobacco.webp',
            },
            backgroundColor: 'char-brown-light',
            title: 'Премиальная<br/> табачная<br/> мешка',
            // description:
            //   'Собранные вручную<br/> высококачественные сорта<br/> табака для насыщенного<br/> вкуса',
          },
          {
            image: {
              src: '/images/products/common/characteristics/filter-xstyle-compact.webp',
            },
            backgroundColor: 'char-grey',
            title: 'Угольный фильтр<br/> с рельефным<br/> покрытием',
            // description:
            //   'Сбалансированный вкус<br/> и дополнительная система<br/> фильтрации ',
          },
        ],
        promo: {
          banner: {
            desktop: {
              src: '/images/products/common/banners/banner-xstyle-silver-desktop.webp',
            },
            mobile: {
              src: '/images/products/common/banners/banner-xstyle-silver-mobile.webp',
            },
          },
          cigPosition: 2,
          titleParts: [
            {
              text: 'компактный',
              perspective: 'top',
              size: 'm',
            },
            {
              text: 'формат',
              perspective: 'bottom',
              size: 'm',
            },
          ],
        },
        sequence: {
          platform: {
            src: '/images/products/common/platforms/platform-xstyle-silver.webp',
          },
          platformShadow: {
            src: '/images/products/common/platforms/shadow/platform-xstyle-silver.webp',
          },
          items: xstyleSilverSequence,
        },
        palette: {
          backgroundEllipseColor: '#E9FDFF',
        },
      },
      {
        id: 'xstyle-dual',
        brand: 'winston',
        brakeAfterBrand: false,
        line: {
          label: 'xstyle',
          alias: 'xstyle',
        },
        type: {
          label: 'dual',
          alias: 'dual',
        },
        showLine: true,
        price: '202 ₽',
        consistency: {
          resine: {
            min: '0',
            max: '10',
            value: '6',
            unit: 'МГ/СИГ**',
            label: 'смола',
          },
          nicotine: {
            min: '0',
            max: '1',
            value: '0.4',
            unit: 'МГ/СИГ**',
            label: 'никотин',
          },
        },
        materials: {
          packOn: {
            src: '/images/products/xstyle/dual/pack-on.webp',
          },
          packOffReflection: {
            src: '/images/products/xstyle/dual/pack-off-reflection.png',
          },
          packOff: {
            src: '/images/products/xstyle/dual/pack-off.webp',
          },
          cig: {
            src: '/images/products/xstyle/dual/cig.webp',
          },
          backgroundDesktop: {
            src: '/images/products/common/backgrounds/background-xstyle-dual-desktop.webp',
          },
          backgroundMobile: {
            src: '/images/products/common/backgrounds/background-xstyle-dual-mobile.webp',
          },
          mainPage: {
            desktop: {
              src: '/images/products/main-page/winston-xstyle-dual-desktop.webp',
            },
            mobile: {
              src: '/images/products/main-page/winston-xstyle-dual-mobile.webp',
            },
            bgBlur: {
              src: '/images/products/main-page/bg-blur.svg',
              srcMob: '/images/products/main-page/bg-blur-mb.svg',
            },
          },
        },
        characteristics: [
          {
            image: {
              src: '/images/products/common/characteristics/two-capsule-dual.webp',
            },
            backgroundColor: 'char-pink-2',
            title: 'две капсулы со вкусом:<br/> ягоды, мята',
            // description:
            //   'Капсулы можно активировать<br/> вместе или по отдельности,<br/> выбирая один из вкусов',
          },
          {
            image: {
              src: '/images/products/common/characteristics/tobacco.webp',
            },
            backgroundColor: 'char-brown-light',
            title: 'Премиальная<br/> табачная<br/> мешка',
            // description:
            //   'Собранные вручную<br/> высококачественные сорта<br/> табака для насыщенного<br/> вкуса',
          },
          {
            image: {
              src: '/images/products/common/characteristics/filter-dual.webp',
            },
            backgroundColor: 'char-blue-ultramarine-2',
            title: 'моноацетатный<br/> фильтр с двумя<br/> капсулами',
            // description: 'Первые сигареты Winston<br/> сразу с двумя капсулами',
          },
        ],
        promo: {
          banner: {
            desktop: {
              src: '/images/products/common/banners/banner-xstyle-dual-desktop.webp',
            },
            mobile: {
              src: '/images/products/common/banners/banner-xstyle-dual-mobile.webp',
            },
          },
          cigPosition: 2,
          titleParts: [
            {
              text: 'компактный',
              perspective: 'top',
              size: 'm',
            },
            {
              text: 'формат',
              perspective: 'bottom',
              size: 'm',
            },
          ],
        },
        sequence: {
          platform: {
            src: '/images/products/common/platforms/platform-xstyle-dual.webp',
          },
          platformShadow: {
            src: '/images/products/common/platforms/shadow/platform-xstyle-dual.webp',
          },
          items: xstyleDualSequence,
        },
        palette: {
          backgroundEllipseColor:
            'linear-gradient(90deg, #DD5DD9 0%, #ED87EA 100%)',
        },
      },
    ],
  },
  {
    line: {
      label: 'xs',
      alias: 'xs',
      id: 'xs',
      description: 'премиальная мешка табака<br> и угольный фильтр',
      defaultLineProduct: 'xs-blue',
    },
    list: [
      {
        id: 'xs-blue',
        brand: 'winston',
        brakeAfterBrand: false,
        line: {
          label: 'xs',
          alias: 'xs',
        },
        type: {
          label: 'blue',
          alias: 'blue',
        },
        showLine: true,
        price: '233 ₽',
        consistency: {
          resine: {
            min: '0',
            max: '10',
            value: '6',
            unit: 'МГ/СИГ**',
            label: 'смола',
          },
          nicotine: {
            min: '0',
            max: '1',
            value: '0.5',
            unit: 'МГ/СИГ**',
            label: 'никотин',
          },
        },
        materials: {
          packOn: {
            src: '/images/products/xs/blue/pack-on.webp',
          },
          packOffReflection: {
            src: '/images/products/xs/blue/pack-off-reflection.webp',
          },
          packOff: {
            src: '/images/products/xs/blue/pack-off.webp',
          },
          cig: {
            src: '/images/products/xs/blue/cig.webp',
          },
          backgroundDesktop: {
            src: '/images/products/common/backgrounds/background-xs-blue-desktop.webp',
          },
          backgroundMobile: {
            src: '/images/products/common/backgrounds/background-xs-blue-mobile.webp',
          },
          mainPage: {
            desktop: {
              src: '/images/products/main-page/winston-xs-blue-desktop.webp',
            },
            mobile: {
              src: '/images/products/main-page/winston-xs-blue-mobile.webp',
            },
            bgBlur: {
              src: '/images/products/main-page/bg-blur.svg',
              srcMob: '/images/products/main-page/bg-blur-mb.svg',
            },
          },
        },
        characteristics: [
          {
            image: {
              src: '/images/products/common/characteristics/filter-xs.webp',
            },
            backgroundColor: 'char-blue',
            title: 'Угольный фильтр<br/> с рельефным<br/> покрытием',
            // description: 'Дополнительная система<br/> очистки сигаретного дыма',
          },
          {
            image: {
              src: '/images/products/common/characteristics/tobacco.webp',
            },
            backgroundColor: 'char-brown-light',
            title: 'Премиальная<br/> табачная<br/> мешка',
            // description:
            //   'Собранные вручную<br/> высококачественные сорта<br/> табака для насыщенного<br/> вкуса',
          },
        ],
        promo: {
          banner: {
            desktop: {
              src: '/images/products/common/banners/banner-xs-blue-desktop.webp',
            },
            mobile: {
              src: '/images/products/common/banners/banner-xs-blue-mobile.webp',
            },
          },
          cigPosition: 2,
          titleParts: [
            {
              text: 'компактный',
              perspective: 'bottom',
              size: 's',
            },
            {
              text: 'супертонкий',
              perspective: 'top',
              size: 'm',
            },
            {
              text: 'формат',
              perspective: 'bottom',
              size: 'm',
            },
          ],
        },
        sequence: {
          platform: {
            src: '/images/products/common/platforms/platform-xs-blue.webp',
          },
          platformShadow: {
            src: '/images/products/common/platforms/shadow/platform-xs-blue.webp',
          },
          items: xsBlueSequence,
        },
        palette: {
          backgroundEllipseColor: '#236cff',
        },
      },
      {
        id: 'xs-silver',
        brand: 'winston',
        brakeAfterBrand: false,
        line: {
          label: 'xs',
          alias: 'xs',
        },
        type: {
          label: 'silver',
          alias: 'silver',
        },
        showLine: true,
        price: '233 ₽',
        consistency: {
          resine: {
            min: '0',
            max: '10',
            value: '4',
            unit: 'МГ/СИГ**',
            label: 'смола',
          },
          nicotine: {
            min: '0',
            max: '1',
            value: '0.4',
            unit: 'МГ/СИГ**',
            label: 'никотин',
          },
        },
        materials: {
          packOn: {
            src: '/images/products/xs/silver/pack-on.webp',
          },
          packOffReflection: {
            src: '/images/products/xs/silver/pack-off-reflection.webp',
          },
          packOff: {
            src: '/images/products/xs/silver/pack-off.webp',
          },
          cig: {
            src: '/images/products/xs/silver/cig.webp',
          },
          backgroundDesktop: {
            src: '/images/products/common/backgrounds/background-xs-silver-desktop.webp',
          },
          backgroundMobile: {
            src: '/images/products/common/backgrounds/background-xs-silver-mobile.webp',
          },
          mainPage: {
            desktop: {
              src: '/images/products/main-page/winston-xs-silver-desktop.webp',
            },
            mobile: {
              src: '/images/products/main-page/winston-xs-silver-mobile.webp',
            },
            bgBlur: {
              src: '/images/products/main-page/bg-blur.svg',
              srcMob: '/images/products/main-page/bg-blur-mb.svg',
            },
          },
        },
        characteristics: [
          {
            image: {
              src: '/images/products/common/characteristics/filter-xs.webp',
            },
            backgroundColor: 'char-grey',
            title: 'Угольный фильтр<br/> с рельефным<br/> покрытием',
            // description: 'Дополнительная система<br/> очистки сигаретного дыма',
          },
          {
            image: {
              src: '/images/products/common/characteristics/tobacco.webp',
            },
            backgroundColor: 'char-brown-light',
            title: 'Премиальная<br/> табачная<br/> мешка',
            // description:
            //   'Собранные вручную<br/> высококачественные сорта<br/> табака для насыщенного<br/> вкуса',
          },
        ],
        promo: {
          banner: {
            desktop: {
              src: '/images/products/common/banners/banner-xs-silver-desktop.webp',
            },
            mobile: {
              src: '/images/products/common/banners/banner-xs-silver-mobile.webp',
            },
          },
          cigPosition: 2,
          titleParts: [
            {
              text: 'компактный',
              perspective: 'bottom',
              size: 's',
            },
            {
              text: 'супертонкий',
              perspective: 'top',
              size: 'm',
            },
            {
              text: 'формат',
              perspective: 'bottom',
              size: 'm',
            },
          ],
        },
        sequence: {
          platform: {
            src: '/images/products/common/platforms/platform-xs-silver.webp',
          },
          platformShadow: {
            src: '/images/products/common/platforms/shadow/platform-xs-silver.webp',
          },
          items: xsSilverSequence,
        },
        palette: {
          backgroundEllipseColor: '#636B71',
        },
      },
      {
        id: 'xs-impulse',
        brand: 'winston',
        brakeAfterBrand: false,
        line: {
          label: 'xs',
          alias: 'xs',
        },
        type: {
          label: 'impulse',
          alias: 'impulse',
        },
        showLine: true,
        price: '225 ₽',
        consistency: {
          resine: {
            min: '0',
            max: '10',
            value: '5',
            unit: 'МГ/СИГ**',
            label: 'смола',
          },
          nicotine: {
            min: '0',
            max: '1',
            value: '0.4',
            unit: 'МГ/СИГ**',
            label: 'никотин',
          },
        },
        materials: {
          packOn: {
            src: '/images/products/xs/impulse/pack-on.webp',
          },
          packOffReflection: {
            src: '/images/products/xs/impulse/pack-off-reflection.webp',
          },
          packOff: {
            src: '/images/products/xs/impulse/pack-off.webp',
          },
          cig: {
            src: '/images/products/xs/impulse/cig.webp',
          },
          backgroundDesktop: {
            src: '/images/products/common/backgrounds/background-xs-impulse-desktop.webp',
          },
          backgroundMobile: {
            src: '/images/products/common/backgrounds/background-xs-impulse-mobile.webp',
          },
          mainPage: {
            desktop: {
              src: '/images/products/main-page/winston-xs-impulse-desktop.webp',
            },
            mobile: {
              src: '/images/products/main-page/winston-xs-impulse-mobile.webp',
            },
            bgBlur: {
              src: '/images/products/main-page/bg-blur.svg',
              srcMob: '/images/products/main-page/bg-blur-mb.svg',
            },
          },
        },
        characteristics: [
          {
            image: {
              src: '/images/products/common/characteristics/capsule-pink.webp',
            },
            backgroundColor: 'char-pink',
            title: 'Капсула<br/> со вкусом<br/> ягод',
            // description:
            //   'Капсула позволяет<br/> изменить вкус сигарет<br/> одним нажатием',
          },
          {
            image: {
              src: '/images/products/common/characteristics/tobacco.webp',
            },
            backgroundColor: 'char-brown-light',
            title: 'Премиальная<br/> табачная<br/> мешка',
            // description:
            //   'Собранные вручную<br/> высококачественные сорта<br/> табака для насыщенного<br/> вкуса',
          },
        ],
        promo: {
          banner: {
            desktop: {
              src: '/images/products/common/banners/banner-xs-impulse-desktop.webp',
            },
            mobile: {
              src: '/images/products/common/banners/banner-xs-impulse-mobile.webp',
            },
          },
          cigPosition: 2,
          titleParts: [
            {
              text: 'компактный',
              perspective: 'bottom',
              size: 's',
            },
            {
              text: 'супертонкий',
              perspective: 'top',
              size: 'm',
            },
            {
              text: 'формат',
              perspective: 'bottom',
              size: 'm',
            },
          ],
        },
        sequence: {
          platform: {
            src: '/images/products/common/platforms/platform-xs-impulse.webp',
          },
          platformShadow: {
            src: '/images/products/common/platforms/shadow/platform-xs-impulse.webp',
          },
          items: xsImpulseSequence,
        },
        palette: {
          backgroundEllipseColor: '#5A164F',
        },
      },
      {
        id: 'xs-arctic',
        brand: 'winston',
        brakeAfterBrand: false,
        line: {
          label: 'xs',
          alias: 'xs',
        },
        type: {
          label: 'arctic',
          alias: 'arctic',
        },
        showLine: true,
        price: '225 ₽',
        consistency: {
          resine: {
            min: '0',
            max: '10',
            value: '5',
            unit: 'МГ/СИГ**',
            label: 'смола',
          },
          nicotine: {
            min: '0',
            max: '1',
            value: '0.4',
            unit: 'МГ/СИГ**',
            label: 'никотин',
          },
        },
        materials: {
          packOn: {
            src: '/images/products/xs/arctic/pack-on.webp',
          },
          packOffReflection: {
            src: '/images/products/xs/arctic/pack-off-reflection.webp',
          },
          packOff: {
            src: '/images/products/xs/arctic/pack-off.webp',
          },
          cig: {
            src: '/images/products/xs/arctic/cig.webp',
          },
          backgroundDesktop: {
            src: '/images/products/common/backgrounds/background-xs-arctic-desktop.webp',
          },
          backgroundMobile: {
            src: '/images/products/common/backgrounds/background-xs-arctic-mobile.webp',
          },
          mainPage: {
            desktop: {
              src: '/images/products/main-page/winston-xs-arctic-desktop.webp',
            },
            mobile: {
              src: '/images/products/main-page/winston-xs-arctic-mobile.webp',
            },
            bgBlur: {
              src: '/images/products/main-page/bg-blur.svg',
              srcMob: '/images/products/main-page/bg-blur-mb.svg',
            },
          },
        },
        characteristics: [
          {
            image: {
              src: '/images/products/common/characteristics/capsule-blue.webp',
            },
            backgroundColor: 'char-green-2',
            title: 'Капсула<br/> со вкусом<br/> свежей мяты',
            // description:
            //   'Капсула позволяет<br/> изменить вкус сигарет<br/> одним нажатием',
          },
          {
            image: {
              src: '/images/products/common/characteristics/tobacco.webp',
            },
            backgroundColor: 'char-brown-light',
            title: 'Премиальная<br/> табачная<br/> мешка',
            // description:
            //   'Собранные вручную<br/> высококачественные сорта<br/> табака для насыщенного<br/> вкуса',
          },
        ],
        promo: {
          banner: {
            desktop: {
              src: '/images/products/common/banners/banner-xs-arctic-desktop.webp',
            },
            mobile: {
              src: '/images/products/common/banners/banner-xs-arctic-mobile.webp',
            },
          },
          cigPosition: 2,
          titleParts: [
            {
              text: 'компактный',
              perspective: 'bottom',
              size: 's',
            },
            {
              text: 'супертонкий',
              perspective: 'top',
              size: 'm',
            },
            {
              text: 'формат',
              perspective: 'bottom',
              size: 'm',
            },
          ],
        },
        sequence: {
          platform: {
            src: '/images/products/common/platforms/platform-xs-arctic.webp',
          },
          platformShadow: {
            src: '/images/products/common/platforms/shadow/platform-xs-arctic.webp',
          },
          items: xsArcticSequence,
        },
        palette: {
          backgroundEllipseColor: '#25A183',
        },
      },
    ],
  },
  {
    line: {
      label: 'xs compact',
      alias: 'xs-compact',
      id: 'xs-compact',
      description:
        'премиальная мешка табака и угольный фильтр в компактном формате',
      defaultLineProduct: 'xs-compact-blue',
    },
    list: [
      {
        id: 'xs-compact-blue',
        brand: 'winston',
        brakeAfterBrand: true,
        line: {
          label: 'xs compact',
          alias: 'xs-compact',
        },
        type: {
          label: 'blue',
          alias: 'blue',
        },
        showLine: true,
        price: '197 ₽',
        consistency: {
          resine: {
            min: '0',
            max: '10',
            value: '6',
            unit: 'МГ/СИГ**',
            label: 'смола',
          },
          nicotine: {
            min: '0',
            max: '1',
            value: '0.5',
            unit: 'МГ/СИГ**',
            label: 'никотин',
          },
        },
        materials: {
          packOn: {
            src: '/images/products/compact/blue/pack-on.webp',
          },
          packOffReflection: {
            src: '/images/products/compact/blue/pack-off-reflection.webp',
          },
          packOff: {
            src: '/images/products/compact/blue/pack-off.webp',
          },
          cig: {
            src: '/images/products/compact/blue/cig.webp',
          },
          backgroundDesktop: {
            src: '/images/products/common/backgrounds/background-compact-blue-desktop.webp',
          },
          backgroundMobile: {
            src: '/images/products/common/backgrounds/background-compact-blue-mobile.webp',
          },
          mainPage: {
            desktop: {
              src: '/images/products/main-page/winston-xs-compact-blue-desktop.webp',
            },
            mobile: {
              src: '/images/products/main-page/winston-xs-compact-blue-mobile.webp',
            },
            bgBlur: {
              src: '/images/products/main-page/bg-blur.svg',
              srcMob: '/images/products/main-page/bg-blur-mb.svg',
            },
          },
        },
        characteristics: [
          {
            image: {
              src: '/images/products/common/characteristics/filter-xstyle-compact.webp',
            },
            backgroundColor: 'char-blue',
            title: 'ФИЛЬТР<br/> С ВОЗДУШНОЙ<br/> КАМЕРОЙ',
            // description:
            //   'Инновационная технология<br/> для сохранения формы<br/> сигарет',
          },
          {
            image: {
              src: '/images/products/common/characteristics/tobacco.webp',
            },
            backgroundColor: 'char-brown-light',
            title: 'Премиальная<br/> табачная<br/> мешка',
            // description:
            //   'Собранные вручную<br/> высококачественные сорта<br/> табака для насыщенного<br/> вкуса',
          },
        ],
        promo: {
          banner: {
            desktop: {
              src: '/images/products/common/banners/banner-compact-blue-desktop.webp',
            },
            mobile: {
              src: '/images/products/common/banners/banner-compact-blue-mobile.webp',
            },
          },
          cigPosition: 2,
          titleParts: [
            {
              text: 'компактный',
              perspective: 'top',
              size: 'm',
            },
            {
              text: 'формат',
              perspective: 'bottom',
              size: 'm',
            },
          ],
        },
        sequence: {
          platform: {
            src: '/images/products/common/platforms/platform-compact-blue.webp',
          },
          platformShadow: {
            src: '/images/products/common/platforms/shadow/platform-compact-blue.webp',
          },
          items: compactBlueSequence,
        },
        palette: {
          backgroundEllipseColor: '#77C3FA',
        },
      },
      {
        id: 'xs-compact-100-blue',
        brand: 'winston',
        brakeAfterBrand: true,
        line: {
          label: 'xs compact',
          alias: 'xs-compact',
        },
        type: {
          label: '100’s BLUE',
          alias: '100-blue',
        },
        showLine: true,
        price: '197 ₽',
        consistency: {
          resine: {
            min: '0',
            max: '10',
            value: '6',
            unit: 'МГ/СИГ**',
            label: 'смола',
          },
          nicotine: {
            min: '0',
            max: '1',
            value: '0.5',
            unit: 'МГ/СИГ**',
            label: 'никотин',
          },
        },
        materials: {
          packOn: {
            src: '/images/products/compact/100-blue/pack-on.webp',
          },
          packOffReflection: {
            src: '/images/products/compact/100-blue/pack-off-reflection.webp',
          },
          packOff: {
            src: '/images/products/compact/100-blue/pack-off.webp',
          },
          cig: {
            src: '/images/products/compact/100-blue/cig.webp',
          },
          backgroundDesktop: {
            src: '/images/products/common/backgrounds/background-compact-blue-desktop.webp',
          },
          backgroundMobile: {
            src: '/images/products/common/backgrounds/background-compact-blue-mobile.webp',
          },
          mainPage: {
            desktop: {
              src: '/images/products/main-page/winston-xs-compact-blue-desktop.webp',
            },
            mobile: {
              src: '/images/products/main-page/winston-xs-compact-blue-mobile.webp',
            },
            bgBlur: {
              src: '/images/products/main-page/bg-blur.svg',
              srcMob: '/images/products/main-page/bg-blur-mb.svg',
            },
          },
        },
        characteristics: [
          {
            image: {
              src: '/images/products/common/characteristics/filter-xstyle-compact.webp',
            },
            backgroundColor: 'char-blue',
            title: 'ФИЛЬТР<br/> С ВОЗДУШНОЙ<br/> КАМЕРОЙ',
            // description:
            //   'Позволяет сохранить<br/> форму сигареты во время<br/> курения',
          },
          {
            image: {
              src: '/images/products/common/characteristics/tobacco.webp',
            },
            backgroundColor: 'char-brown-light',
            title: 'Премиальная<br/> табачная<br/> мешка',
            // description:
            //   'Собранные вручную<br/> высококачественные сорта<br/> табака для насыщенного<br/> вкуса',
          },
          {
            mod: '25-percent',
            image: {
              src: '/images/products/common/characteristics/25-percent.webp',
            },
            backgroundColor: 'char-blue-dark',
            title: 'НА 25% больше<br/> табака',
            description: 'По сравнению с Winston<br/> XS Compact Blue',
          },
        ],
        promo: {
          banner: {
            desktop: {
              src: '/images/products/common/banners/banner-compact-blue-desktop.webp',
            },
            mobile: {
              src: '/images/products/common/banners/banner-compact-blue-mobile.webp',
            },
          },
          cigPosition: 2,
          titleParts: [
            {
              text: 'компактный',
              perspective: 'bottom',
              size: 's',
            },
            {
              text: 'УДЛИНЕННЫЙ',
              perspective: 'top',
              size: 'm',
            },
            {
              text: 'формат',
              perspective: 'bottom',
              size: 'm',
            },
          ],
        },
        sequence: {
          platform: {
            src: '/images/products/common/platforms/platform-compact-blue.webp',
          },
          platformShadow: {
            src: '/images/products/common/platforms/shadow/platform-compact-blue.webp',
          },
          items: compact100BlueSequence,
        },
        palette: {
          backgroundEllipseColor: '#77C3FA',
        },
      },
      {
        id: 'xs-compact-electro',
        brand: 'winston',
        brakeAfterBrand: true,
        line: {
          label: 'xs compact',
          alias: 'xs-compact',
        },
        type: {
          label: 'electro',
          alias: 'electro',
        },
        showLine: true,
        price: '197 ₽',
        consistency: {
          resine: {
            min: '0',
            max: '10',
            value: '6',
            unit: 'МГ/СИГ**',
            label: 'смола',
          },
          nicotine: {
            min: '0',
            max: '1',
            value: '0.5',
            unit: 'МГ/СИГ**',
            label: 'никотин',
          },
        },
        materials: {
          packOn: {
            src: '/images/products/compact/electro/pack-on.webp',
          },
          packOffReflection: {
            src: '/images/products/compact/electro/pack-off-reflection.webp',
          },
          packOff: {
            src: '/images/products/compact/electro/pack-off.webp',
          },
          cig: {
            src: '/images/products/compact/electro/cig.webp',
          },
          backgroundDesktop: {
            src: '/images/products/common/backgrounds/background-compact-electro-desktop.webp',
          },
          backgroundMobile: {
            src: '/images/products/common/backgrounds/background-compact-electro-mobile.webp',
          },
          mainPage: {
            desktop: {
              src: '/images/products/main-page/winston-xs-compact-electro-desktop.webp',
            },
            mobile: {
              src: '/images/products/main-page/winston-xs-compact-electro-mobile.webp',
            },
            bgBlur: {
              src: '/images/products/main-page/bg-blur.svg',
              srcMob: '/images/products/main-page/bg-blur-mb.svg',
            },
          },
        },
        characteristics: [
          {
            image: {
              src: '/images/products/common/characteristics/capsule-pink.webp',
            },
            backgroundColor: 'char-pink',
            title: 'Капсула<br/> со вкусом<br/> ягод',
            // description:
            //   'Капсула позволяет<br/> изменить вкус сигарет<br/> одним нажатием',
          },
          {
            image: {
              src: '/images/products/common/characteristics/tobacco.webp',
            },
            backgroundColor: 'char-brown',
            title: 'Премиальная<br/> табачная<br/> мешка',
            // description:
            //   'Собранные вручную<br/> высококачественные сорта<br/> табака для насыщенного<br/> вкуса',
          },
          {
            image: {
              src: '/images/products/common/characteristics/filter-electro.webp',
            },
            backgroundColor: 'char-blue',
            title: 'ФИЛЬТР<br/> С ВОЗДУШНОЙ<br/> КАМЕРОЙ',
            // description:
            //   'Обеспечивает<br/> сбалансированный<br/> и глубокий вкус',
          },
        ],
        promo: {
          banner: {
            desktop: {
              src: '/images/products/common/banners/banner-compact-electro-desktop.webp',
            },
            mobile: {
              src: '/images/products/common/banners/banner-compact-electro-mobile.webp',
            },
          },
          cigPosition: 2,
          titleParts: [
            {
              text: 'компактный',
              perspective: 'top',
              size: 'm',
            },
            {
              text: 'формат',
              perspective: 'bottom',
              size: 'm',
            },
          ],
        },
        sequence: {
          platform: {
            src: '/images/products/common/platforms/platform-compact-blue.webp',
          },
          platformShadow: {
            src: '/images/products/common/platforms/shadow/platform-compact-blue.webp',
          },
          items: compactElectroSequence,
        },
        palette: {
          backgroundEllipseColor: '#9F006C',
        },
      },
      {
        id: 'xs-compact-frozen',
        brand: 'winston',
        brakeAfterBrand: true,
        line: {
          label: 'xs compact',
          alias: 'xs-compact',
        },
        type: {
          label: 'frozen',
          alias: 'frozen',
        },
        showLine: true,
        price: '197 ₽',
        consistency: {
          resine: {
            min: '0',
            max: '10',
            value: '6',
            unit: 'МГ/СИГ**',
            label: 'смола',
          },
          nicotine: {
            min: '0',
            max: '1',
            value: '0.5',
            unit: 'МГ/СИГ**',
            label: 'никотин',
          },
        },
        materials: {
          packOn: {
            src: '/images/products/compact/frozen/pack-on.webp',
          },
          packOffReflection: {
            src: '/images/products/compact/frozen/pack-off-reflection.webp',
          },
          packOff: {
            src: '/images/products/compact/frozen/pack-off.webp',
          },
          cig: {
            src: '/images/products/compact/frozen/cig.webp',
          },
          backgroundDesktop: {
            src: '/images/products/common/backgrounds/background-compact-frozen-desktop.webp',
          },
          backgroundMobile: {
            src: '/images/products/common/backgrounds/background-compact-frozen-mobile.webp',
          },
          mainPage: {
            desktop: {
              src: '/images/products/main-page/winston-xs-compact-frozen-desktop.webp',
            },
            mobile: {
              src: '/images/products/main-page/winston-xs-compact-frozen-mobile.webp',
            },
            bgBlur: {
              src: '/images/products/main-page/bg-blur.svg',
              srcMob: '/images/products/main-page/bg-blur-mb.svg',
            },
          },
        },
        characteristics: [
          {
            image: {
              src: '/images/products/common/characteristics/capsule-blue-light.webp',
            },
            backgroundColor: 'char-blue-light',
            title: 'Капсула<br/> со вкусом<br/> свежей мяты',
            // description:
            //   'Капсула позволяет<br/> изменить вкус сигарет<br/> одним нажатием',
          },
          {
            image: {
              src: '/images/products/common/characteristics/tobacco.webp',
            },
            backgroundColor: 'char-brown',
            title: 'Премиальная<br/> табачная<br/> мешка',
            // description:
            //   'Собранные вручную<br/> высококачественные сорта<br/> табака для насыщенного<br/> вкуса',
          },
          {
            image: {
              src: '/images/products/common/characteristics/filter-frozen.webp',
            },
            backgroundColor: 'char-blue',
            title: 'ФИЛЬТР<br/> С ВОЗДУШНОЙ<br/> КАМЕРОЙ',
            // description:
            //   'Обеспечивает<br/> сбалансированный<br/> и глубокий вкус',
          },
        ],
        promo: {
          banner: {
            desktop: {
              src: '/images/products/common/banners/banner-compact-frozen-desktop.webp',
            },
            mobile: {
              src: '/images/products/common/banners/banner-compact-frozen-mobile.webp',
            },
          },
          cigPosition: 2,
          titleParts: [
            {
              text: 'компактный',
              perspective: 'top',
              size: 'm',
            },
            {
              text: 'формат',
              perspective: 'bottom',
              size: 'm',
            },
          ],
        },
        sequence: {
          platform: {
            src: '/images/products/common/platforms/platform-compact-frozen.webp',
          },
          platformShadow: {
            src: '/images/products/common/platforms/shadow/platform-compact-frozen.webp',
          },
          items: compactFrozenSequence,
        },
        palette: {
          backgroundEllipseColor: '#03F3C3',
        },
      },
      {
        id: 'xs-compact-costa',
        brand: 'winston',
        brakeAfterBrand: true,
        line: {
          label: 'xs compact',
          alias: 'xs-compact',
        },
        type: {
          label: 'costa',
          alias: 'costa',
        },
        showLine: true,
        price: '197 ₽',
        consistency: {
          resine: {
            min: '0',
            max: '10',
            value: '6',
            unit: 'МГ/СИГ**',
            label: 'смола',
          },
          nicotine: {
            min: '0',
            max: '1',
            value: '0.5',
            unit: 'МГ/СИГ**',
            label: 'никотин',
          },
        },
        materials: {
          packOn: {
            src: '/images/products/compact/costa/pack-on.webp',
          },
          packOffReflection: {
            src: '/images/products/compact/costa/pack-off-reflection.webp',
          },
          packOff: {
            src: '/images/products/compact/costa/pack-off.webp',
          },
          cig: {
            src: '/images/products/compact/costa/cig.webp',
          },
          backgroundDesktop: {
            src: '/images/products/common/backgrounds/background-compact-costa-desktop.webp',
          },
          backgroundMobile: {
            src: '/images/products/common/backgrounds/background-compact-costa-mobile.webp',
          },
          mainPage: {
            desktop: {
              src: '/images/products/main-page/winston-xs-compact-costa-desktop.webp',
            },
            mobile: {
              src: '/images/products/main-page/winston-xs-compact-costa-mobile.webp',
            },
            bgBlur: {
              src: '/images/products/main-page/bg-blur.svg',
              srcMob: '/images/products/main-page/bg-blur-mb.svg',
            },
          },
        },
        characteristics: [
          {
            image: {
              src: '/images/products/common/characteristics/capsule-orange.webp',
            },
            backgroundColor: 'char-orange',
            title: 'Капсула<br/> со вкусом<br/> апельсина',
            // description:
            //   'Капсула позволяет<br/> изменить вкус сигарет<br/> одним нажатием',
          },
          {
            pin: {
              icon: 'aroma-blend',
              backgroundColor: '#7942294D',
            },
            image: {
              src: '/images/products/common/characteristics/tobacco.webp',
            },
            backgroundColor: 'char-chocolate',
            title: 'табачная мешка<br/> с ароматом<br/> шоколада ',
            // description: 'Особый вкус <br/>табачной мешки',
          },
          {
            image: {
              src: '/images/products/common/characteristics/filter-costa.webp',
            },
            backgroundColor: 'char-blue-pink',
            title: 'ФИЛЬТР<br/> С ВОЗДУШНОЙ<br/> КАМЕРОЙ',
            // description:
            //   'Обеспечивает<br/> сбалансированный<br/> и глубокий вкус',
          },
        ],
        promo: {
          banner: {
            desktop: {
              src: '/images/products/common/banners/banner-compact-costa-desktop.webp',
            },
            mobile: {
              src: '/images/products/common/banners/banner-compact-costa-mobile.webp',
            },
          },
          cigPosition: 2,
          titleParts: [
            {
              text: 'компактный',
              perspective: 'top',
              size: 'm',
            },
            {
              text: 'формат',
              perspective: 'bottom',
              size: 'm',
            },
          ],
        },
        sequence: {
          platform: {
            src: '/images/products/common/platforms/platform-compact-costa.webp',
          },
          platformShadow: {
            src: '/images/products/common/platforms/shadow/platform-compact-costa.webp',
          },
          items: compactCostaSequence,
        },
        palette: {
          backgroundEllipseColor: '#FF7300',
        },
      },
    ],
  },
  {
    line: {
      label: 'xs kiss',
      alias: 'xs-kiss',
      id: 'xs-kiss',
      description: 'Современные сигареты<br> в супертонком формате*',
      note: '<sup>*</sup> Представлены в портфеле бренда Winston с 2023 года',
      themes: {
        mainPage: 'black',
      },
      defaultLineProduct: 'xs-kiss-glam',
    },
    list: [
      {
        id: 'xs-kiss-glam',
        brand: 'winston',
        brakeAfterBrand: false,
        line: {
          label: 'xs kiss',
          alias: 'xs-kiss',
        },
        type: {
          label: 'glam',
          alias: 'glam',
        },
        showLine: true,
        price: '197 ₽',
        consistency: {
          resine: {
            min: '0',
            max: '10',
            value: '4',
            unit: 'МГ/СИГ**',
            label: 'смола',
          },
          nicotine: {
            min: '0',
            max: '1',
            value: '0.4',
            unit: 'МГ/СИГ**',
            label: 'никотин',
          },
        },
        materials: {
          packOn: {
            src: '/images/products/kiss/glam/pack-on.webp',
          },
          packOffReflection: {
            src: '/images/products/kiss/glam/pack-off-reflection.webp',
          },
          packOff: {
            src: '/images/products/kiss/glam/pack-off.webp',
          },
          cig: {
            src: '/images/products/kiss/glam/cig.webp',
          },
          backgroundDesktop: {
            src: '/images/products/common/backgrounds/background-kiss-glam-desktop.webp',
          },
          backgroundMobile: {
            src: '/images/products/common/backgrounds/background-kiss-glam-mobile.webp',
          },
          mainPage: {
            desktop: {
              src: '/images/products/main-page/winston-xs-kiss-glam-desktop.webp',
            },
            mobile: {
              src: '/images/products/main-page/winston-xs-kiss-glam-mobile.webp',
            },
            bgBlur: {
              src: '/images/products/main-page/bg-xs-kiss-glam-blur.svg',
              srcMob: '/images/products/main-page/bg-xs-kiss-glam-blur-mb.svg',
            },
          },
        },
        characteristics: [
          {
            image: {
              src: '/images/products/common/characteristics/capsule-orange-pink.webp',
            },
            backgroundColor: 'char-pink-orange',
            title: 'со вкусом<br/> спелых фруктов',
            // description:
            //   'Капсула позволяет<br/> изменить вкус сигарет<br/> одним нажатием',
          },
          {
            image: {
              src: '/images/products/common/characteristics/filter-glam.webp',
            },
            backgroundColor: 'char-pink-light',
            title: 'моноацетатный<br/> фильтр<br/> с капсулой',
            // description:
            //   'Обеспечивает<br/> сбалансированный<br/> и глубокий вкус',
          },
        ],
        promo: {
          banner: {
            desktop: {
              src: '/images/products/common/banners/banner-kiss-glam-desktop.webp',
            },
            mobile: {
              src: '/images/products/common/banners/banner-kiss-glam-mobile.webp',
            },
          },
          cigPosition: 2,
          titleParts: [
            {
              text: 'супертонкий',
              perspective: 'top',
              size: 'm',
            },
            {
              text: 'формат',
              perspective: 'bottom',
              size: 'm',
            },
          ],
        },
        sequence: {
          platform: {
            src: '/images/products/common/platforms/platform-kiss-glam.webp',
          },
          platformShadow: {
            src: '/images/products/common/platforms/shadow/platform-kiss-glam.webp',
          },
          items: kissGlamSequence,
        },
        palette: {
          backgroundEllipseColor: '#FA77A7',
        },
      },
      {
        id: 'xs-kiss-cosmo',
        brand: 'winston',
        brakeAfterBrand: false,
        line: {
          label: 'xs kiss',
          alias: 'xs-kiss',
        },
        type: {
          label: 'cosmo',
          alias: 'cosmo',
        },
        showLine: true,
        price: '197 ₽',
        consistency: {
          resine: {
            min: '0',
            max: '10',
            value: '5',
            unit: 'МГ/СИГ**',
            label: 'смола',
          },
          nicotine: {
            min: '0',
            max: '1',
            value: '0.5',
            unit: 'МГ/СИГ**',
            label: 'никотин',
          },
        },
        materials: {
          packOn: {
            src: '/images/products/kiss/cosmo/pack-on.webp',
          },
          packOffReflection: {
            src: '/images/products/kiss/cosmo/pack-off-reflection.webp',
          },
          packOff: {
            src: '/images/products/kiss/cosmo/pack-off.webp',
          },
          cig: {
            src: '/images/products/kiss/cosmo/cig.webp',
          },
          backgroundDesktop: {
            src: '/images/products/common/backgrounds/background-kiss-cosmo-desktop.webp',
          },
          backgroundMobile: {
            src: '/images/products/common/backgrounds/background-kiss-cosmo-mobile.webp',
          },
          mainPage: {
            desktop: {
              src: '/images/products/main-page/winston-xs-kiss-cosmo-desktop.webp',
            },
            mobile: {
              src: '/images/products/main-page/winston-xs-kiss-cosmo-mobile.webp',
            },
            bgBlur: {
              src: '/images/products/main-page/bg-xs-kiss-glam-blur.svg',
              srcMob: '/images/products/main-page/bg-xs-kiss-glam-blur-mb.svg',
            },
          },
        },
        characteristics: [
          {
            image: {
              src: '/images/products/common/characteristics/capsule-green.webp',
            },
            backgroundColor: 'char-blue-green',
            title: 'со вкусом<br/> свежей мяты',
            // description:
            //   'Капсула позволяет<br/> изменить вкус сигарет<br/> одним нажатием',
          },
          {
            image: {
              src: '/images/products/common/characteristics/filter-cosmo.webp',
            },
            backgroundColor: 'char-silver',
            title: 'моноацетатный<br/> фильтр<br/> с капсулой',
            // description:
            //   'Обеспечивает<br/> сбалансированный<br/> и глубокий вкус',
          },
        ],
        promo: {
          banner: {
            desktop: {
              src: '/images/products/common/banners/banner-kiss-cosmo-desktop.webp',
            },
            mobile: {
              src: '/images/products/common/banners/banner-kiss-cosmo-mobile.webp',
            },
          },
          cigPosition: 2,
          titleParts: [
            {
              text: 'супертонкий',
              perspective: 'top',
              size: 'm',
            },
            {
              text: 'формат',
              perspective: 'bottom',
              size: 'm',
            },
          ],
        },
        sequence: {
          platform: {
            src: '/images/products/common/platforms/platform-kiss-cosmo.webp',
          },
          platformShadow: {
            src: '/images/products/common/platforms/shadow/platform-kiss-cosmo.webp',
          },
          items: kissCosmoSequence,
        },
        palette: {
          backgroundEllipseColor: '#C79CEF',
        },
      },
      {
        id: 'xs-kiss-dream',
        brand: 'winston',
        brakeAfterBrand: false,
        line: {
          label: 'xs kiss',
          alias: 'xs-kiss',
        },
        type: {
          label: 'dream',
          alias: 'dream',
        },
        showLine: true,
        price: '197 ₽',
        consistency: {
          resine: {
            min: '0',
            max: '10',
            value: '5',
            unit: 'МГ/СИГ**',
            label: 'смола',
          },
          nicotine: {
            min: '0',
            max: '1',
            value: '0.5',
            unit: 'МГ/СИГ**',
            label: 'никотин',
          },
        },
        materials: {
          packOn: {
            src: '/images/products/kiss/dream/pack-on.webp',
          },
          packOffReflection: {
            src: '/images/products/kiss/dream/pack-off-reflection.webp',
          },
          packOff: {
            src: '/images/products/kiss/dream/pack-off.webp',
          },
          cig: {
            src: '/images/products/kiss/dream/cig.webp',
          },
          backgroundDesktop: {
            src: '/images/products/common/backgrounds/background-kiss-dream-desktop.webp',
          },
          backgroundMobile: {
            src: '/images/products/common/backgrounds/background-kiss-dream-mobile.webp',
          },
          mainPage: {
            desktop: {
              src: '/images/products/main-page/winston-xs-kiss-dream-desktop.webp',
            },
            mobile: {
              src: '/images/products/main-page/winston-xs-kiss-dream-mobile.webp',
            },
            bgBlur: {
              src: '/images/products/main-page/bg-xs-kiss-glam-blur.svg',
              srcMob: '/images/products/main-page/bg-xs-kiss-glam-blur-mb.svg',
            },
          },
        },
        characteristics: [
          {
            image: {
              src: '/images/products/common/characteristics/filter-ss-kiss.webp',
            },
            backgroundColor: 'char-pale-pink',
            title: 'моноацетатный<br/> фильтр',
            // description:
            //   'Обеспечивает<br/> сбалансированный<br/> и глубокий вкус',
          },
          {
            image: {
              src: '/images/products/common/characteristics/tobacco.webp',
            },
            backgroundColor: 'char-beige',
            title: 'Классическая<br/> табачная мешка',
            // description:
            //   'Собранные вручную<br/> высококачественные<br/> сорта табака',
          },
        ],
        promo: {
          banner: {
            desktop: {
              src: '/images/products/common/banners/banner-kiss-dream-desktop.webp',
            },
            mobile: {
              src: '/images/products/common/banners/banner-kiss-dream-mobile.webp',
            },
          },
          cigPosition: 2,
          titleParts: [
            {
              text: 'супертонкий',
              perspective: 'top',
              size: 'm',
            },
            {
              text: 'формат',
              perspective: 'bottom',
              size: 'm',
            },
          ],
        },
        sequence: {
          platform: {
            src: '/images/products/common/platforms/platform-kiss-dream.webp',
          },
          platformShadow: {
            src: '/images/products/common/platforms/shadow/platform-kiss-dream.webp',
          },
          items: kissDreamSequence,
        },
        palette: {
          backgroundEllipseColor: '#EF9CD4',
        },
      },
      {
        id: 'xs-kiss-jolly',
        brand: 'winston',
        brakeAfterBrand: false,
        line: {
          label: 'xs kiss',
          alias: 'xs-kiss',
        },
        type: {
          label: 'jolly',
          alias: 'jolly',
        },
        showLine: true,
        price: '197 ₽',
        consistency: {
          resine: {
            min: '0',
            max: '10',
            value: '5',
            unit: 'МГ/СИГ**',
            label: 'смола',
          },
          nicotine: {
            min: '0',
            max: '1',
            value: '0.5',
            unit: 'МГ/СИГ**',
            label: 'никотин',
          },
        },
        materials: {
          packOn: {
            src: '/images/products/kiss/jolly/pack-on.webp',
          },
          packOffReflection: {
            src: '/images/products/kiss/jolly/pack-off-reflection.webp',
          },
          packOff: {
            src: '/images/products/kiss/jolly/pack-off.webp',
          },
          cig: {
            src: '/images/products/kiss/jolly/cig.webp',
          },
          backgroundDesktop: {
            src: '/images/products/common/backgrounds/background-kiss-jolly-desktop.webp',
          },
          backgroundMobile: {
            src: '/images/products/common/backgrounds/background-kiss-jolly-mobile.webp',
          },
          mainPage: {
            desktop: {
              src: '/images/products/main-page/winston-xs-kiss-jolly-desktop.webp',
            },
            mobile: {
              src: '/images/products/main-page/winston-xs-kiss-jolly-mobile.webp',
            },
            bgBlur: {
              src: '/images/products/main-page/bg-xs-kiss-glam-blur.svg',
              srcMob: '/images/products/main-page/bg-xs-kiss-glam-blur-mb.svg',
            },
          },
        },
        characteristics: [
          {
            pin: {
              icon: 'aroma-blend',
              backgroundColor: '#DA6E784D',
            },
            image: {
              src: '/images/products/common/characteristics/tobacco.webp',
            },
            backgroundColor: 'char-pink-red',
            title: 'табачная мешка<br/> с ароматом<br/> клубники',
            // description: 'Особый вкус табачной<br/> мешки',
          },
          {
            image: {
              src: '/images/products/common/characteristics/filter-ss-kiss.webp',
            },
            backgroundColor: 'char-orange-grey',
            title: 'моноацетатный<br/> фильтр',
            // description:
            //   'Обеспечивает<br/> сбалансированный<br/> и глубокий вкус',
          },
        ],
        promo: {
          banner: {
            desktop: {
              src: '/images/products/common/banners/banner-kiss-jolly-desktop.webp',
            },
            mobile: {
              src: '/images/products/common/banners/banner-kiss-jolly-mobile.webp',
            },
          },
          cigPosition: 2,
          titleParts: [
            {
              text: 'супертонкий',
              perspective: 'top',
              size: 'm',
            },
            {
              text: 'формат',
              perspective: 'bottom',
              size: 'm',
            },
          ],
        },
        sequence: {
          platform: {
            src: '/images/products/common/platforms/platform-kiss-jolly.webp',
          },
          platformShadow: {
            src: '/images/products/common/platforms/shadow/platform-kiss-jolly.webp',
          },
          items: kissJollySequence,
        },
        palette: {
          backgroundEllipseColor: '#F97582',
        },
      },
      {
        id: 'xs-kiss-menthol',
        brand: 'winston',
        brakeAfterBrand: false,
        line: {
          label: 'xs kiss',
          alias: 'xs-kiss',
        },
        type: {
          label: 'menthol',
          alias: 'menthol',
        },
        showLine: true,
        price: '197 ₽',
        consistency: {
          resine: {
            min: '0',
            max: '10',
            value: '4',
            unit: 'МГ/СИГ**',
            label: 'смола',
          },
          nicotine: {
            min: '0',
            max: '1',
            value: '0.4',
            unit: 'МГ/СИГ**',
            label: 'никотин',
          },
        },
        materials: {
          packOn: {
            src: '/images/products/kiss/menthol/pack-on.webp',
          },
          packOffReflection: {
            src: '/images/products/kiss/menthol/pack-off-reflection.webp',
          },
          packOff: {
            src: '/images/products/kiss/menthol/pack-off.webp',
          },
          cig: {
            src: '/images/products/kiss/menthol/cig.webp',
          },
          backgroundDesktop: {
            src: '/images/products/common/backgrounds/background-kiss-menthol-desktop.webp',
          },
          backgroundMobile: {
            src: '/images/products/common/backgrounds/background-kiss-menthol-mobile.webp',
          },
          mainPage: {
            desktop: {
              src: '/images/products/main-page/winston-xs-kiss-menthol-desktop.webp',
            },
            mobile: {
              src: '/images/products/main-page/winston-xs-kiss-menthol-mobile.webp',
            },
            bgBlur: {
              src: '/images/products/main-page/bg-xs-kiss-glam-blur.svg',
              srcMob: '/images/products/main-page/bg-xs-kiss-glam-blur-mb.svg',
            },
          },
        },
        characteristics: [
          {
            image: {
              src: '/images/products/common/characteristics/menthol.webp',
            },
            backgroundColor: 'char-menthol',
            title: 'ментоловый<br/> фильтр',
            // description: 'Особая свежесть<br/> во время курения',
          },
          {
            image: {
              src: '/images/products/common/characteristics/tobacco.webp',
            },
            backgroundColor: 'char-beige',
            title: 'Классическая<br/> табачная мешка',
            // description:
            //   'Собранные вручную<br/> высококачественные<br/> сорта табака',
          },
        ],
        promo: {
          banner: {
            desktop: {
              src: '/images/products/common/banners/banner-kiss-menthol-desktop.webp',
            },
            mobile: {
              src: '/images/products/common/banners/banner-kiss-menthol-mobile.webp',
            },
          },
          cigPosition: 2,
          titleParts: [
            {
              text: 'супертонкий',
              perspective: 'top',
              size: 'm',
            },
            {
              text: 'формат',
              perspective: 'bottom',
              size: 'm',
            },
          ],
        },
        sequence: {
          platform: {
            src: '/images/products/common/platforms/platform-kiss-menthol.webp',
          },
          platformShadow: {
            src: '/images/products/common/platforms/shadow/platform-kiss-menthol.webp',
          },
          items: kissMentholSequence,
        },
        palette: {
          backgroundEllipseColor: '#78B8B2',
        },
      },
      {
        id: 'xs-kiss-mirage',
        brand: 'winston',
        brakeAfterBrand: false,
        line: {
          label: 'xs kiss',
          alias: 'xs-kiss',
        },
        type: {
          label: 'mirage',
          alias: 'mirage',
        },
        showLine: true,
        price: '197 ₽',
        consistency: {
          resine: {
            min: '0',
            max: '10',
            value: '5',
            unit: 'МГ/СИГ**',
            label: 'смола',
          },
          nicotine: {
            min: '0',
            max: '1',
            value: '0.4',
            unit: 'МГ/СИГ**',
            label: 'никотин',
          },
        },
        materials: {
          packOn: {
            src: '/images/products/kiss/mirage/pack-on.webp',
          },
          packOffReflection: {
            src: '/images/products/kiss/mirage/pack-off-reflection.webp',
          },
          packOff: {
            src: '/images/products/kiss/mirage/pack-off.webp',
          },
          cig: {
            src: '/images/products/kiss/mirage/cig.webp',
          },
          backgroundDesktop: {
            src: '/images/products/common/backgrounds/background-kiss-mirage-desktop.webp',
          },
          backgroundMobile: {
            src: '/images/products/common/backgrounds/background-kiss-mirage-mobile.webp',
          },
          mainPage: {
            desktop: {
              src: '/images/products/main-page/winston-xs-kiss-mirage-desktop.webp',
            },
            mobile: {
              src: '/images/products/main-page/winston-xs-kiss-mirage-mobile.webp',
            },
            bgBlur: {
              src: '/images/products/main-page/bg-xs-kiss-glam-blur.svg',
              srcMob: '/images/products/main-page/bg-xs-kiss-glam-blur-mb.svg',
            },
          },
        },
        characteristics: [
          {
            pin: {
              icon: 'aroma-blend',
              backgroundColor: '#7942294D',
            },
            image: {
              src: '/images/products/common/characteristics/tobacco.webp',
            },
            backgroundColor: 'char-chocolate-light',
            title: 'табачная мешка<br/> с ароматом<br/> шоколада ',
            // description: 'Особый вкус<br/> табачной мешки',
          },
          {
            image: {
              src: '/images/products/common/characteristics/capsule-orange.webp',
            },
            backgroundColor: 'char-orange-yellow',
            title: 'капсула<br/> со вкусом<br/> апельсина',
            // description:
            //   'Капсула позволяет<br/> изменить вкус сигарет<br/> одним нажатием',
          },
          {
            pin: {
              icon: 'sweet-tipping',
              backgroundColor: '#7942294D',
            },
            image: {
              src: '/images/products/common/characteristics/sweet-tipping.webp',
            },
            backgroundColor: 'char-beige-orange',
            title: 'сладкое<br/> покрытие<br/> на фильтре',
            // description:
            //   'Обеспечивает<br/> сбалансированный<br/> и глубокий вкус',
          },
        ],
        promo: {
          banner: {
            desktop: {
              src: '/images/products/common/banners/banner-kiss-mirage-desktop.webp',
            },
            mobile: {
              src: '/images/products/common/banners/banner-kiss-mirage-mobile.webp',
            },
          },
          cigPosition: 2,
          titleParts: [
            {
              text: 'супертонкий',
              perspective: 'top',
              size: 'm',
            },
            {
              text: 'формат',
              perspective: 'bottom',
              size: 'm',
            },
          ],
        },
        sequence: {
          platform: {
            src: '/images/products/common/platforms/platform-kiss-mirage.webp',
          },
          platformShadow: {
            src: '/images/products/common/platforms/shadow/platform-kiss-mirage.webp',
          },
          items: kissMirageSequence,
        },
        palette: {
          backgroundEllipseColor: '#CB7555',
        },
      },
    ],
  },
];

export default products;
