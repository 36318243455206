import { useModal } from 'vue-final-modal';
import { defineAsyncComponent, ref } from '#imports';

const isPopupVisible = ref(false);
export const useSessionEnded = () => {
  if (isPopupVisible.value) return;
  const openSessionEndedPopup = ({
    heading,
    title,
    description,
    buttonData,
  }) => {
    const { open, close } = useModal({
      modalId: 'sessionEnded',
      component: defineAsyncComponent(
        () => import('@/components/popups/Info.vue'),
      ),
      attrs: {
        closeModal: () => {
          close();
        },
        onClosed() {
          isPopupVisible.value = false;
        },
        heading: heading,
        title: title,
        description: description,
        buttonData: buttonData,
      },
    });

    open();
  };

  isPopupVisible.value = true;

  return {
    openSessionEndedPopup,
  };
};
